import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useForm } from "react-hook-form";
import RHFMoneyField2 from "../../FormControlsRHF/RHFMoneyField2";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import RHFCheckbox from "../../FormControlsRHF/RHFCheckbox";
import Grid2 from "@mui/material/Unstable_Grid2";
import CommissionIncentivesTable from "./CommissionIncentivesTable";

const genDefaultValues = () => {
  return {
    amount: null,
    memo: "",
    reimbursable: false,
  };
};

const CommissionIncentivesCard = ({ agencyId, incentives, api, refresh }) => {
  const defaultValues = genDefaultValues();

  const { control, handleSubmit, setError, reset } = useForm({ defaultValues });

  const onSuccess = () => {
    refresh();
    reset();
  };

  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    onSuccess
  );

  const onSubmit = (data) => {
    apiWrapper(api.addCommissionBonus(agencyId, data));
  };

  return (
    <Card variant="outlined">
      <CardHeader title="Commission Incentives" />
      {incentives.length > 0 && (
        <CardContent>
          <CommissionIncentivesTable incentives={incentives} />
        </CardContent>
      )}
      <CardContent>
        <FormLevelErrorAlert sx={{ mb: 2 }} formErrStruct={formErrState} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid2 container columnSpacing={2}>
            <Grid2 xs={4}>
              <RHFMoneyField2
                control={control}
                name="amount"
                label="Bonus Amount"
              />
            </Grid2>
            <Grid2 xs={6}>
              <RHFTextField control={control} name="memo" label="Memo" />
            </Grid2>
            <Grid2 xs={2}>
              <RHFCheckbox
                control={control}
                name="reimbursable"
                label="Reimbursable?"
              />
            </Grid2>
            <Grid2 xs={12} sx={{ textAlign: "right" }}>
              <Button variant="contained" type="submit">
                Add Bonus
              </Button>
            </Grid2>
          </Grid2>
        </form>
      </CardContent>
    </Card>
  );
};

export default CommissionIncentivesCard;
