import { useState } from "react";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import DaisyBillIcon from "../StandardComponents/Icons/DaisyBillIcon";

const PageDebug = () => {
  const [dateState, setDateState] = useState([null, null]);

  return <DaisyBillIcon disabled />;
};

export default PageDebug;
