import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import LinkRx from "../../StandardComponents/Link/LinkRx";
import LinkOrd from "../../StandardComponents/Link/LinkOrd";
import { genToStr } from "../../../utility";
import LinkEoc from "../../StandardComponents/Link/LinkEoc";
import { moneyFmt } from "../../../formatters";
import RHFMoneyField2 from "../../FormControlsRHF/RHFMoneyField2";
import { useWatch } from "react-hook-form";
import PaidIcon from "@mui/icons-material/Paid";
import ARIgnoreIcon from "./ARIgnoreIcon";

const searchFields = (fieldList, orderId, lineId = null) => {
  const idx = fieldList.findIndex(
    (a) => a.order_id === orderId && a.ol_id === lineId
  );
  if (idx === -1) {
    throw new Error("could not find field array entry");
  }
  return [fieldList[idx], idx];
};

const olIdIsARIgnored = (lines, olId) => {
  const line = lines.find((l) => l.order_line_id === olId);
  if (!line) return false;
  return !!line.ar_ignored_on;
};

const OrderLevelBalance = ({ name, control, orderId, lines }) => {
  const newState = useWatch({ name, control });
  const newBalance = newState
    .filter((s) => s.order_id === orderId)
    .reduce((acc, cur) => {
      const isIgnored = olIdIsARIgnored(lines, cur.ol_id);
      const lineBalance = isIgnored
        ? 0
        : cur.balance_init + (cur.alloc_init - cur.alloc);
      return acc + lineBalance;
    }, 0);
  return (
    <>
      Order Balance: <strong>{moneyFmt(newBalance)}</strong>
    </>
  );
};

const OrderLineBalance = ({ name, control, isIgnored }) => {
  const newLineState = useWatch({ name, control });
  const balanceCurrent =
    newLineState.balance_init + (newLineState.alloc_init - newLineState.alloc);
  return isIgnored ? (
    <Tooltip
      title={`Ignored balance: ${moneyFmt(balanceCurrent)}`}
      arrow
      placement="top"
    >
      <span>$0.00</span>
    </Tooltip>
  ) : (
    moneyFmt(balanceCurrent)
  );
};

const OrderAllocCardSubheader = ({
  rxId,
  orderId,
  generation,
  therapy,
  dos,
}) => {
  const rxLink = <LinkRx id={rxId} copyBtn={false} />;
  const ordLink = <LinkOrd id={orderId} copyBtn={false} />;
  const genStr = genToStr(generation);
  const therStr = therapy.toUpperCase();

  return (
    <>
      <strong>{therStr}</strong> - <strong>{genStr}</strong> | {rxLink} |{" "}
      {ordLink} | Date of Service: <strong>{dos}</strong>
    </>
  );
};

const OrderAllocCard = ({
  eocId,
  firstName,
  lastName,
  rxId,
  orderId,
  therapy,
  generation,
  control,
  lines,
  fields,
  dos,
  setterFn,
  initiateARIgnoreFn,
}) => {
  const [, ordAllocIdx] = searchFields(fields, orderId, null);
  const ordAllocPrefix = `allocations.${ordAllocIdx}`;

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <LinkEoc id={eocId} name_first={firstName} name_last={lastName} />
        }
        subheader={
          <OrderAllocCardSubheader
            rxId={rxId}
            orderId={orderId}
            generation={generation}
            therapy={therapy}
            dos={dos}
          />
        }
        action={
          <OrderLevelBalance
            name="allocations"
            control={control}
            orderId={orderId}
            lines={lines}
          />
        }
      />
      <CardContent sx={{ py: 0 }}>
        <Divider sx={{ mb: 1 }} />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "140px" }}>Overpayment:</Box>
          <Box>
            <RHFMoneyField2
              sx={{
                m: 0,
                p: 0,
                "& input": { p: "3px", fontSize: "smaller" },
              }}
              control={control}
              name={`${ordAllocPrefix}.alloc`}
              helperText=""
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
        </Box>
        <Table
          size="small"
          sx={{
            "& tr td:nth-of-type(5)": {
              px: 0,
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell sx={{ width: "120px" }}>Charge</TableCell>
              <TableCell sx={{ width: "120px" }}>Balance</TableCell>
              <TableCell sx={{ width: "150px" }}>Allocation</TableCell>
              <TableCell sx={{ width: "70px" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lines.map((l) => {
              const [fld, idx] = searchFields(fields, orderId, l.order_line_id);
              const fldPrefix = `allocations.${idx}`;
              const fldName = `${fldPrefix}.alloc`;
              const sx = {};
              if (l.ar_ignored_on) sx.bgcolor = "bgError";

              return (
                <TableRow key={`ordl-${l.order_line_id}`} sx={sx}>
                  <TableCell>{l.description}</TableCell>
                  <TableCell>{moneyFmt(l.qty * l.charge)}</TableCell>
                  <TableCell>
                    <OrderLineBalance
                      name={fldPrefix}
                      control={control}
                      isIgnored={!!l.ar_ignored_on}
                    />
                  </TableCell>
                  <TableCell>
                    <RHFMoneyField2
                      sx={{
                        m: 0,
                        p: 0,
                        "& input": { p: "3px", fontSize: "smaller" },
                      }}
                      control={control}
                      name={fldName}
                      helperText=""
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ alignItems: "center" }}>
                      <IconButton
                        size="small"
                        color="success"
                        onClick={() => {
                          setterFn(
                            `${fldPrefix}.alloc`,
                            fld.alloc_init + fld.balance_init,
                            { shouldDirty: true }
                          );
                        }}
                      >
                        <PaidIcon fontSize="small" />
                      </IconButton>
                      <ARIgnoreIcon
                        control={control}
                        balance={l.balance}
                        ignoreARFn={() => initiateARIgnoreFn(l.order_line_id)}
                        ar_ignored_on={l.ar_ignored_on}
                        ar_ignored_user_name={l.ar_ignored_user_name}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default OrderAllocCard;
