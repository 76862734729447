import { useEffect, useState } from "react";
import { Box, Stack, Tab, Tabs, TextField } from "@mui/material";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import SectionHeading from "../PagePartials/SectionHeading";
import StdFormDialog from "../PagePartials/StdFormDialog";
import SplitButton from "../StandardComponents/SplitButton";
import RPPhoneNumberInput from "../FormControls/RPPhoneNumberInput";
import RPRepRolesPicker from "../FormControls/RPRepRolesPicker";
import RPStatePicker from "../FormControls/RPStatePicker";
import RPUserPicker from "../FormControls/RPUserPicker";
import AgencyTabPanelDashboard from "./PageAgency/AgencyTabPanelDashboard";
import AgencyTabPanelRoster from "./PageAgency/AgencyTabPanelRoster";
import AgencyTabPanelInventory from "./PageAgency/AgencyTabPanelInventory";
import AgencyTabPanelCommissions from "./PageAgency/AgencyTabPanelCommissions";
import useDialogState from "../../HOC/useDialogState";
import AgencyTabPanelPrescribers from "./PageAgency/AgencyTabPanelPrescribers";
import TicketContextMenu from "../PagePartials/Tickets/TicketContextMenu";

const addRepForm = [
  { name: "name", component: TextField, label: "Rep Name" },
  {
    name: "phone",
    component: RPPhoneNumberInput,
    label: "Rep Phone",
    width: 6,
  },
  { name: "email", component: TextField, label: "Rep Email", width: 6 },
  { name: "roles", component: RPRepRolesPicker, label: "Roles" },
];

const addLocForm = [
  { name: "title", component: TextField, label: "Location Name" },
  {
    name: "addr_street1",
    component: TextField,
    label: "Street Address",
    width: 7,
  },
  { name: "addr_street2", component: TextField, label: "Suite/Unit", width: 5 },
  { name: "addr_city", component: TextField, label: "City", width: 5 },
  { name: "addr_state", component: RPStatePicker, label: "State", width: 4 },
  { name: "addr_zip", component: TextField, label: "Zip Code", width: 3 },
];

const setApsForm = [
  {
    name: "aps_employee_num",
    component: TextField,
    label: "Employee Number",
    width: 6,
  },
  {
    name: "aps_employee_name",
    component: TextField,
    label: "Employee Name",
    width: 6,
  },
];

const TABS_VALID = [
  "main",
  "roster",
  "prescribers",
  "inventory",
  "commissions",
];

const getInitialTab = () => {
  const savedTab = localStorage.getItem("agency-tab");
  if (TABS_VALID.includes(savedTab)) return savedTab;
  localStorage.setItem("agency-tab", TABS_VALID[0]);
  return TABS_VALID[0];
};

const PageAgency = ({ pageData, refresh, api }) => {
  const [activeTab, setActiveTab] = useState(getInitialTab());
  const handleTabChange = (e, newVal) => setActiveTab(newVal);

  useEffect(() => {
    localStorage.setItem("agency-tab", activeTab);
  }, [activeTab]);

  const {
    open: addRepFormOpen,
    openFn: handleAddRepFormOpen,
    closeFn: handleAddRepFormClose,
  } = useDialogState();

  const {
    open: addLocFormOpen,
    openFn: handleAddLocFormOpen,
    closeFn: handleAddLocFormClose,
  } = useDialogState();

  const {
    open: setApsFormOpen,
    openFn: handleSetApsFormOpen,
    closeFn: handleSetApsFormClose,
  } = useDialogState();

  const {
    open: setGuidesFormOpen,
    openFn: handleSetGuidesFormOpen,
    closeFn: handleSetGuidesFormClose,
  } = useDialogState();

  const setGuidesForm = [
    {
      name: "guide_patient_id",
      component: RPUserPicker,
      label: "Patient Guide",
      width: 7,
      extraProps: { api },
    },
    {
      name: "guide_practice_id",
      component: RPUserPicker,
      label: "Practice Guide",
      width: 7,
      extraProps: { api },
    },
    {
      name: "guide_sales_id",
      component: RPUserPicker,
      label: "Sales Guide",
      width: 7,
      extraProps: { api },
    },
  ];

  // dynamically add items to split button to avoid confusion
  const splitBtnOpts = {};
  if (activeTab === "main") {
    splitBtnOpts["Set Assigned Guides"] = () => handleSetGuidesFormOpen();
  }
  if (activeTab === "inventory") {
    splitBtnOpts["Add Address"] = () => handleAddLocFormOpen();
  }
  if (activeTab === "roster") {
    splitBtnOpts["Add Rep"] = () => handleAddRepFormOpen();
  }
  if (activeTab === "commissions" && !pageData.aps_entered_on) {
    splitBtnOpts["Set APS Data"] = () => handleSetApsFormOpen();
  }

  const guides = {
    patient: {
      id: pageData.guides.ptng_id,
      name: pageData.guides.ptng_name,
      picture: pageData.guides.ptng_picture,
    },
    practice: {
      id: pageData.guides.prcg_id,
      name: pageData.guides.prcg_name,
      picture: pageData.guides.prcg_picture,
    },
    sales: {
      id: pageData.guides.salg_id,
      name: pageData.guides.salg_name,
      picture: pageData.guides.salg_picture,
    },
  };

  return (
    <>
      <BackLinkHeader title="Back to Agency List" to="/agencies" />

      <SectionHeading
        headingLabel={`${pageData.name}`}
        buttonEl={
          <Stack direction="row" spacing={1}>
            <SplitButton optsAndClicks={splitBtnOpts} />
            <TicketContextMenu
              api={api}
              context={{
                type: "agencies",
                ident: pageData.id,
                label: pageData.name,
              }}
            />
          </Stack>
        }
      />

      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab index={0} value="main" label="Overview" />
        <Tab index={1} value="roster" label="Roster" />
        <Tab index={2} value="prescribers" label="Prescribers" />
        <Tab index={3} value="inventory" label="Inventory" />
        <Tab index={4} value="commissions" label="Commissions" />
      </Tabs>

      <Box sx={{ my: 3 }} />

      <AgencyTabPanelDashboard
        activeTab={activeTab}
        refresh={refresh}
        api={api}
        agencyId={pageData.id}
        prescribers={pageData.prescribers}
        attachments={pageData.attach}
        guides={guides}
        salesArea={pageData.sales_area}
        reps={pageData.reps}
        totalSweptComms={pageData.total_swept_comms}
        serviceChannel={pageData.service_channel}
      />

      <AgencyTabPanelRoster activeTab={activeTab} reps={pageData.reps} />

      <AgencyTabPanelPrescribers
        activeTab={activeTab}
        reps={pageData.reps}
        prescribers={pageData.prescribers}
      />

      <AgencyTabPanelInventory
        activeTab={activeTab}
        inventory={pageData.inventory}
        agencyId={pageData.id}
        locations={pageData.locs}
      />

      <AgencyTabPanelCommissions
        activeTab={activeTab}
        commissionModel={pageData.comm_model}
        commMatrix={pageData.commPlans}
        agencyId={pageData.id}
        incentives={pageData.incentives}
        apsInfo={{
          employee_name: pageData.aps_employee_name,
          employee_num: pageData.aps_employee_num,
          entered_on: pageData.aps_entered_on,
          fix_error_fn: handleSetApsFormOpen,
        }}
        api={api}
        refresh={refresh}
      />

      <StdFormDialog
        fields={addRepForm}
        onClose={handleAddRepFormClose}
        open={addRepFormOpen}
        onComplete={() => {
          refresh();
          handleAddRepFormClose();
        }}
        title="New Rep"
        submitCall={(data) => {
          data.agency_id = pageData.id;
          data.roles = data.roles.map((r) => r.toLowerCase());
          return api.newRep(data);
        }}
      />

      <StdFormDialog
        fields={addLocForm}
        onClose={handleAddLocFormClose}
        open={addLocFormOpen}
        onComplete={() => {
          refresh();
          handleAddLocFormClose();
        }}
        title="New Address"
        submitCall={(data) => api.newAgencyLoc(pageData.id, data)}
      />

      <StdFormDialog
        fields={setApsForm}
        onClose={handleSetApsFormClose}
        open={setApsFormOpen}
        onComplete={() => {
          refresh();
          handleSetApsFormClose();
        }}
        title="APS Payment Data"
        submitCall={(data) => api.setAgencyAps(pageData.id, data)}
      />

      <StdFormDialog
        fields={setGuidesForm}
        onClose={handleSetGuidesFormClose}
        open={setGuidesFormOpen}
        title="Set Assigned Guides"
        initialVals={{
          guide_patient_id: guides.patient.id,
          guide_practice_id: guides.practice.id,
          guide_sales_id: guides.sales.id,
        }}
        onComplete={() => {
          refresh();
          handleSetGuidesFormClose();
        }}
        submitCall={(data) => {
          const payload = {
            guide_patient_id: parseInt(data.guide_patient_id, 10),
            guide_practice_id: parseInt(data.guide_practice_id, 10),
            guide_sales_id: parseInt(data.guide_sales_id, 10),
          };
          return api.agencySetGuides(pageData.id, payload);
        }}
      />
    </>
  );
};

export default PageAgency;
