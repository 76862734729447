import Grid2 from "@mui/material/Unstable_Grid2";
import { Divider } from "@mui/material";
import ConfigurationCard from "./ConfigurationCard";
import PrimaryContactCard from "./PrimaryContactCard";
import NotesDispEditForm from "./NotesDispEditForm";
import AttachmentListCard from "../../PagePartials/AttachmentListCard";
import PrescEditSettingsDialog from "./PrescEditSettingsDialog";
import useDialogState from "../../../HOC/useDialogState";
import AttributionSurface from "./AttributionSurface";

const PrescriberTabPanelMain = ({ activeTab, api, refresh, pageData }) => {
  const { open, openFn, closeFn } = useDialogState();

  if (activeTab !== "main") {
    return null;
  }

  const pracContact = !!pageData.pcon_id
    ? {
        name_first: pageData.pcon_name_first,
        name_last: pageData.pcon_name_last,
        type: pageData.pcon_type,
        pref_cont_method: pageData.pcon_pref_cont_method,
        phone: pageData.pcon_phone,
        fax: pageData.pcon_fax,
        email: pageData.pcon_email,
      }
    : null;

  return (
    <>
      <Grid2 container columnSpacing={2} rowSpacing={2}>
        <Grid2 xs={12}>
          <AttributionSurface
            agencyId={pageData.agency_id}
            agencyName={pageData.agency_name}
            repId={pageData.rep_id}
            repName={pageData.rep_name}
          />
        </Grid2>
        <Grid2 xs={7}>
          <ConfigurationCard
            service_channel={pageData.service_channel}
            ret_and_growth={pageData.ret_and_growth}
            allow_ext={pageData.allow_ext}
            com_pref_ext={pageData.com_pref_ext}
            com_pref_missing={pageData.com_pref_missing}
            fit_pref={pageData.fit_pref}
            phone={pageData.phone}
            email={pageData.email}
            onEditIconClick={openFn}
          />
        </Grid2>
        <Grid2 xs={5}>
          <PrimaryContactCard pracContact={pracContact} />
        </Grid2>
        <Grid2 xs={12}>
          <Divider />
        </Grid2>
        <Grid2 xs={12}>
          <NotesDispEditForm
            api={api}
            npi={pageData.npi}
            refresh={refresh}
            notes={pageData.notes}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Divider />
        </Grid2>
        <Grid2 xs={12}>
          <AttachmentListCard
            editable
            attachmentData={pageData.attachments}
            refresh={refresh}
            api={api}
            targetEntity="prescribers"
            targetId={pageData.id}
          />
        </Grid2>
      </Grid2>
      <PrescEditSettingsDialog
        npi={pageData.npi}
        open={open}
        onClose={closeFn}
        api={api}
        refresh={refresh}
        initialVals={{
          allow_ext: pageData.allow_ext,
          ret_and_growth: pageData.ret_and_growth,
          com_pref_ext: pageData.com_pref_ext,
          com_pref_missing: pageData.com_pref_missing,
          fit_pref: pageData.fit_pref,
          phone: pageData.phone,
          email: pageData.email,
        }}
      />
    </>
  );
};

export default PrescriberTabPanelMain;
