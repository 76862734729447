import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import RHFDatePicker from "../../FormControlsRHF/RHFDatePicker";
import CloseIcon from "@mui/icons-material/Close";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import RHFDebug from "../../RHFDebug";

const genDefaultValues = () => {
  return {
    as_of: new Date(),
    qty: 0,
    audit_notes: "",
  };
};

const TrueUpDialogForm = ({
  open,
  onClose,
  agencyId,
  itemId,
  agencyName,
  itemName,
  api,
  refresh,
}) => {
  const defaultValues = genDefaultValues();
  const { control, handleSubmit, setError, reset } = useForm({ defaultValues });
  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      reset(defaultValues);
      refresh();
      handleClose();
    }
  );

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (data) => {
    const as_of = data.as_of;
    if (as_of !== null) {
      as_of.setHours(18);
      as_of.setMinutes(0);
      as_of.setSeconds(0);
      as_of.setMilliseconds(0);
    }

    const payload = [
      {
        item_id: itemId,
        as_of: as_of,
        qty: parseInt(data.qty, 10),
        audit_notes: data.audit_notes,
      },
    ];

    apiWrapper(api.agencyTrueup(agencyId, payload));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle sx={{ pb: 1 }}>Enter True Up</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent sx={{ pt: 1 }}>
        <FormLevelErrorAlert formErrStruct={formErrState} />
        <Typography sx={{ mb: 2 }}>
          Assert that {agencyName} has a given quantity of {itemName} on the
          given date:
        </Typography>
        <Stack direction="row" spacing={1} sx={{ pt: "6px" }}>
          <RHFDatePicker
            size="small"
            control={control}
            name="as_of"
            label="As Of Date"
          />
          <RHFTextField
            type="number"
            control={control}
            label="Quantity"
            name="qty"
            fullWidth={false}
            sx={{ maxWidth: "120px" }}
          />
        </Stack>
        <RHFTextField
          control={control}
          name="audit_notes"
          multiline
          label="Audit Notes"
          rows={6}
          InputProps={{
            sx: { fontFamily: "monospace", fontSize: "smaller" },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit">Enter True Up</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrueUpDialogForm;
