import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Alert,
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import StandardDialog from "../../StandardComponents/StandardDialog";
import RHFUPSServiceCodePicker, {
  OPTIONS as serviceCodes,
} from "../../FormControlsRHF/RHFUPSServiceCodePicker";
import RHFUPSConfirmationPicker, {
  OPTIONS as confirmations,
} from "../../FormControlsRHF/RHFUPSConfirmationPicker";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import RHFZipCodeField from "../../FormControlsRHF/RHFZipCodeField";
import RHFStatePicker from "../../FormControlsRHF/RHFStatePicker";
import RHFPhoneField from "../../FormControlsRHF/RHFPhoneField";
import RHFCheckbox from "../../FormControlsRHF/RHFCheckbox";
import { useState } from "react";
import { getByPath } from "../../../utility";

const setFormError = (setFn, msg) => {
  setFn({ submitting: false, showErrors: true, globalErrMsg: msg });
};

const clearFormError = (setFn) => {
  setFn({ submitting: false, showErrors: false, globalErrMsg: "" });
};

const setSubmitting = (setFn) => {
  setFn((prev) => ({ ...prev, submitting: true }));
};

const clearSubmitting = (setFn) => {
  setFn((prev) => ({ ...prev, submitting: false }));
};

const GenerateLabelFormDialog = ({
  open,
  onClose,
  initialVals,
  api,
  refresh,
}) => {
  const srvc = initialVals;

  const [state, setState] = useState({
    submitting: false,
    showErrors: false,
    globalErrMsg: "",
  });

  const defaultPackageWeight = srvc.therapy === "sam" ? 7 : 25;
  const ptnName = [srvc.eoc.name_first, srvc.eoc.name_last]
    .filter((_) => !!_)
    .join(" ");
  const toState = RHFStatePicker.byVal(srvc.addr_state);
  const fromState = RHFStatePicker.byVal("MI");

  const defaultValues = {
    service_code:
      RHFUPSServiceCodePicker.findOpt(srvc.pref_service_code) ||
      serviceCodes[0],
    confirmation: confirmations[1],
    saturday_delivery: false,
    include_return: true,
    package_weight: defaultPackageWeight,
    ship_to: {
      company_name: "",
      name: ptnName,
      phone: "",
      address_line1: srvc.addr_street1,
      address_line2: srvc.addr_street2,
      city_locality: srvc.addr_city,
      state_province: toState,
      postal_code: srvc.addr_zip,
    },
    ship_from: {
      company_name: "WR Specialists LLC",
      name: "WRS Warehouse",
      phone: "7349292160",
      address_line1: "251 Jackson Plaza",
      address_line2: "Ste A2",
      city_locality: "Ann Arbor",
      state_province: fromState,
      postal_code: "48103",
    },
  };

  const { control, handleSubmit, reset, setError } = useForm({ defaultValues });

  const onSubmit = (data) => {
    const payload = { ...data };

    payload.package_weight = parseInt(payload.package_weight, 10);
    payload.service_code = data.service_code.value;
    payload.confirmation = data.confirmation.value;
    payload.ship_to.state_province = data.ship_to.state_province?.value;
    payload.ship_from.state_province = data.ship_from.state_province?.value;

    // TODO: add reference data
    payload.reference1 = "";
    payload.reference2 = "";

    setSubmitting(setState);
    api
      .genLabel(srvc.id, payload)
      .then((resp) => {
        clearSubmitting(setState);
        if (resp.status === "error") {
          setFormError(setState, resp.message);
          return;
        }

        // if validation, add errors back in
        if (resp.status === "validation") {
          const unmatched = [];
          Object.entries(resp.errors).forEach(([k, m]) => {
            if (getByPath(defaultValues, k)) {
              setError(k, { type: "server", message: m });
            } else {
              unmatched.push(m);
            }
          });
          if (unmatched.length > 0) {
            setFormError(setState, unmatched);
          }
          return;
        }

        // otherwise refresh
        refresh();
        handleClose();
      })
      .catch((err) => setFormError(setState, err.message));
  };

  const handleClose = () => {
    onClose();
    clearFormError(setState);
    reset();
  };

  return (
    <StandardDialog
      open={open}
      onClose={handleClose}
      title="Generate UPS Label"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container columnSpacing={2} rowSpacing={0}>
          {state.showErrors && (
            <Grid2 xs={12}>
              <Alert severity="error" sx={{ mb: 2 }}>
                {Array.isArray(state.globalErrMsg) ? (
                  <ul>
                    {state.globalErrMsg.map((m) => (
                      <li key={m}>{m}</li>
                    ))}
                  </ul>
                ) : (
                  state.globalErrMsg
                )}
              </Alert>
            </Grid2>
          )}
          <Grid2 xs={3}>
            <Typography variant="h6">Shipping Options</Typography>
          </Grid2>
          <Grid2 xs={4}>
            <RHFUPSServiceCodePicker
              control={control}
              name="service_code"
              label="UPS Service"
            />
          </Grid2>
          <Grid2 xs={4}>
            <RHFUPSConfirmationPicker
              control={control}
              name="confirmation"
              label="Delivery Confirmation"
            />
          </Grid2>
          <Grid2 xs={1}></Grid2>

          <Grid2 xs={3}></Grid2>
          <Grid2 xs={2}>
            <RHFTextField
              control={control}
              name="package_weight"
              label="Weight"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">lbs</InputAdornment>
                ),
              }}
            />
          </Grid2>
          <Grid2 xs={3}>
            <RHFCheckbox
              control={control}
              name="saturday_delivery"
              label="Saturday Delivery"
            />
          </Grid2>
          <Grid2 xs={4}>
            <RHFCheckbox
              control={control}
              name="include_return"
              label="Include Return Label"
            />
          </Grid2>

          <Grid2 xs={12}>
            <Divider sx={{ mb: 2 }} />
          </Grid2>

          <Grid2 xs={3}>
            <Typography variant="h6">Ship To</Typography>
          </Grid2>
          <Grid2 xs={9}>
            <RHFTextField
              control={control}
              name="ship_to.company_name"
              label="Recipient Company Name"
            />
          </Grid2>

          <Grid2 xs={3}></Grid2>
          <Grid2 xs={6}>
            <RHFTextField
              control={control}
              name="ship_to.name"
              label="Recipient Name"
            />
          </Grid2>
          <Grid2 xs={3}>
            <RHFPhoneField
              control={control}
              name="ship_to.phone"
              label="Recipient Phone"
            />
          </Grid2>

          <Grid2 xs={3}></Grid2>
          <Grid2 xs={5}>
            <RHFTextField
              control={control}
              name="ship_to.address_line1"
              label="Street Address"
            />
          </Grid2>
          <Grid2 xs={4}>
            <RHFTextField
              control={control}
              name="ship_to.address_line2"
              label="Suite / Apt"
            />
          </Grid2>

          <Grid2 xs={3}></Grid2>
          <Grid2 xs={4}>
            <RHFTextField
              control={control}
              name="ship_to.city_locality"
              label="City"
            />
          </Grid2>
          <Grid2 xs={3}>
            <RHFStatePicker
              control={control}
              name="ship_to.state_province"
              label="State"
            />
          </Grid2>
          <Grid2 xs={2}>
            <RHFZipCodeField
              control={control}
              name="ship_to.postal_code"
              label="Zip Code"
            />
          </Grid2>

          <Grid2 xs={12}>
            <Divider sx={{ mb: 2 }} />
          </Grid2>

          <Grid2 xs={3}>
            <Typography variant="h6">Ship From</Typography>
          </Grid2>
          <Grid2 xs={9}>
            <RHFTextField
              control={control}
              name="ship_from.company_name"
              label="Sender Company Name"
            />
          </Grid2>
          <Grid2 xs={3}></Grid2>
          <Grid2 xs={6}>
            <RHFTextField
              control={control}
              name="ship_from.name"
              label="Sender Name"
            />
          </Grid2>
          <Grid2 xs={3}>
            <RHFPhoneField
              control={control}
              name="ship_from.phone"
              label="Sender Phone"
            />
          </Grid2>

          <Grid2 xs={3}></Grid2>
          <Grid2 xs={5}>
            <RHFTextField
              control={control}
              name="ship_from.address_line1"
              label="Street Address"
            />
          </Grid2>
          <Grid2 xs={4}>
            <RHFTextField
              control={control}
              name="ship_from.address_line2"
              label="Suite / Apt"
            />
          </Grid2>

          <Grid2 xs={3}></Grid2>
          <Grid2 xs={4}>
            <RHFTextField
              control={control}
              name="ship_from.city_locality"
              label="City"
            />
          </Grid2>
          <Grid2 xs={3}>
            <RHFStatePicker
              control={control}
              name="ship_from.state_province"
              label="State"
            />
          </Grid2>
          <Grid2 xs={2}>
            <RHFZipCodeField
              control={control}
              name="ship_from.postal_code"
              label="Zip Code"
            />
          </Grid2>

          <Grid2 xs={12}>
            <Stack direction="row-reverse">
              <Button
                type="submit"
                variant="contained"
                disabled={state.submitting}
              >
                {state.submitting ? "Creating..." : "Create Label"}
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                onClick={() => {
                  reset();
                  clearFormError(setState);
                }}
                disabled={state.submitting}
                color="error"
                variant="outlined"
              >
                Reset
              </Button>
            </Stack>
          </Grid2>
        </Grid2>
      </form>
    </StandardDialog>
  );
};

export default GenerateLabelFormDialog;
