import { Alert, AlertTitle, Button } from "@mui/material";
import useDialogState from "../../../HOC/useDialogState";
import StdConfirmSubmitDialog from "../../PagePartials/StdConfirmSubmitDialog";

const OrderValidationAlert = ({
  orderId,
  validationsList,
  api,
  refresh,
  ...rest
}) => {
  const { open, openFn, closeFn } = useDialogState();

  if (validationsList.length === 0) return null;

  return (
    <>
      <Alert
        {...rest}
        severity="warning"
        action={
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={openFn}
          >
            Clear
          </Button>
        }
      >
        <AlertTitle>Order Notices</AlertTitle>
        <ul>
          {validationsList.map((v) => (
            <li key={v.reval_code}>{v.memo}</li>
          ))}
        </ul>
      </Alert>
      <StdConfirmSubmitDialog
        title="Clear Order Notices"
        open={open}
        handleClose={closeFn}
        onComplete={() => {
          closeFn();
          refresh();
        }}
        onSubmit={() => api.markOrderVerified(orderId)}
      >
        This will clear all order notices!
      </StdConfirmSubmitDialog>
    </>
  );
};

export default OrderValidationAlert;
