import StdConfirmSubmitDialog from "../PagePartials/StdConfirmSubmitDialog";

const ValidateOrder = ({ initialVals, api, open, onClose, refresh }) => {
  return (
    <StdConfirmSubmitDialog
      title="Mark Order Validated"
      open={open}
      handleClose={onClose}
      onComplete={() => {
        onClose();
        refresh();
      }}
      onSubmit={() => api.markOrderVerified(initialVals.order.id)}
    >
      Mark order as verified?
    </StdConfirmSubmitDialog>
  );
};

export default ValidateOrder;
