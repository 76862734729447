import { criticalSection, rxNeedsDoc } from "./Workspaces/critical.js";
import {
  billInFull,
  extNotEligible,
  fittingNeeded,
  initialCall,
  patientExtensionOffer,
  patientSection,
  pickupPlan,
  postponedPatients,
  sevenDayCall,
} from "./Workspaces/patient-team";
import {
  practiceExtensionOffer,
  practiceIncomplete,
  practiceSection,
} from "./Workspaces/practice-team";

import {
  billingSection,
  orderHasNotice,
  orderNeedsAssignment,
  orderNeedsBilling,
  orderPending,
  orderPendingPo,
} from "./Workspaces/billing-team";
import {
  awaitingPickup,
  inTransit,
  logisticsSection,
  packingNeeded,
  plannedShipping,
  reprocessing,
  returnedDevices,
  shippingNeeded,
} from "./Workspaces/logistics";
import { psrFittings, psrPickups, psrSection } from "./Workspaces/psrs";
import { achievementLog, achievementSection } from "./Workspaces/achievements";
import {
  repBabies,
  repBleeders,
  repGainers,
  salesSection,
} from "./Workspaces/sales";

export const BUCKET_SECTIONS = [
  criticalSection,
  salesSection,
  patientSection,
  practiceSection,
  logisticsSection,
  billingSection,
  psrSection,
  achievementSection,
];

const buckets = [
  // critical
  rxNeedsDoc,

  // sales
  repGainers,
  repBleeders,
  repBabies,

  // patients
  initialCall,
  postponedPatients,
  fittingNeeded,
  sevenDayCall,
  patientExtensionOffer,
  extNotEligible,
  pickupPlan,
  billInFull,

  // practices
  practiceExtensionOffer,
  practiceIncomplete,

  // logistics
  plannedShipping,
  packingNeeded,
  shippingNeeded,
  inTransit,
  awaitingPickup,
  returnedDevices,
  reprocessing,

  // billing
  orderNeedsAssignment,
  orderHasNotice,
  orderPending,
  orderPendingPo,
  orderNeedsBilling,

  // field
  psrFittings,
  psrPickups,

  // achievements
  achievementLog,
];

/**
 * This is a globally shared data structure that should never be mutated
 */
export const BUCKET_LIST = Object.fromEntries(buckets);

export const sectionByKey = (key) => {
  return BUCKET_SECTIONS.find((bs) => key === bs.key);
};

export const sectionByBucketKey = (bucketKey) => {
  let sec = null;
  BUCKET_SECTIONS.forEach((bs) => {
    if (bs.buckets.includes(bucketKey)) {
      sec = bs.key;
    }
  });
  return sec;
};
