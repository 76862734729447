import { Box, Container, Grid } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import TopBar from "./TopBar/TopBar";
import { withPageLevelCall } from "../../HOC/withPageLevelCall";
import SideNav from "./SideNav/SideNav";
import PageHome from "../Pages/PageHome";
import PageDebug from "../Pages/PageDebug";
import PageSettings from "../Pages/PageSettings";
import PageNotFound from "../Pages/PageNotFound";
import PageAgencies from "../Pages/PageAgencies";
import PageReps from "../Pages/PageReps";
import PageIntakes from "../Pages/PageIntakes";
import PageAgency from "../Pages/PageAgency";
import PageRep from "../Pages/PageRep";
import PagePractices from "../Pages/PagePractices";
import PagePractice from "../Pages/PagePractice";
import PagePurchaseOrders from "../Pages/PagePurchaseOrders";
import PagePurchaseOrder from "../Pages/PagePurchaseOrder";
import PageTransferOrders from "../Pages/PageTransferOrders";
import PageTransferOrderNew from "../Pages/PageTransferOrderNew";
import PageTransferOrder from "../Pages/PageTransferOrder";
import PageIntake from "../Pages/PageIntake";
import PagePatients from "../Pages/PagePatients";
import PagePatient from "../Pages/PagePatient";
import PagePrescriberOnboardings from "../Pages/PagePrescriberOnboardings";
import PagePrescriberOnboarding from "../Pages/PagePrescriberOnboarding";
import PageServiceCycle from "../Pages/PageServiceCycle";
import PageReports from "../Pages/PageReports";
import PageOrgs from "../Pages/PageOrgs";
import PageOrg from "../Pages/PageOrg";
import PageRx from "../Pages/PageRx";
import PageOrder from "../Pages/PageOrder";
import PagePayments from "../Pages/PagePayments";
import PagePayment from "../Pages/PagePayment.js";
import PageTest from "../Pages/PageTest";
import PageTreatmentAttachments from "../Pages/PageTreatmentAttachments";
import PagePrescriber from "../Pages/PagePrescriber";
import PageWorkspace from "../Pages/PageWorkspace";
import PageUnit from "../Pages/PageUnit";
import PageCommissionDates from "../Pages/PageCommissionDates";
import PageCommissions from "../Pages/PageCommissions";
import PageMassTexter from "../Pages/PageMassTexter";
import PageAgencyItemHistory from "../Pages/PageAgencyItemHistory";
import PagePracticeEngagementEmailGlobals from "../Pages/PagePracticeEngagementEmailGlobals";
import PageTickets from "../Pages/PageTickets";
import PageTreatments from "../Pages/PageTreatments";
import PageTicket from "../Pages/PageTicket";
import PageNotificationList from "../Pages/PageNotificationList";

const LayoutMain = ({ api, toggleThemeMode }) => {
  const PageServiceCycleApi = withPageLevelCall(
    PageServiceCycle,
    ({ srvcId }) => api.getServiceCycle(srvcId)
  );
  const PageAgenciesApi = withPageLevelCall(PageAgencies, () =>
    api.getAgencies()
  );
  const PageAgencyApi = withPageLevelCall(PageAgency, ({ agencyId }) =>
    api.getAgency(agencyId)
  );
  const PageAgencyItemHistoryApi = withPageLevelCall(
    PageAgencyItemHistory,
    ({ agencyId, itemId }) => api.getAgencyItemHistory(agencyId, itemId)
  );
  const PageRepApi = withPageLevelCall(PageRep, ({ repId }) =>
    api.getRep(repId)
  );
  const PageRepsApi = withPageLevelCall(PageReps, () => api.getReps());
  const PagePracticesApi = withPageLevelCall(PagePractices, () =>
    api.getPractices()
  );
  const PagePracticeApi = withPageLevelCall(PagePractice, ({ practiceId }) =>
    api.getPractice(practiceId)
  );

  const PagePurchaseOrderApi = withPageLevelCall(
    PagePurchaseOrder,
    ({ poId }) => api.getPo(poId)
  );
  const PageTransferOrdersApi = withPageLevelCall(PageTransferOrders, () =>
    api.getTos()
  );
  const PageTransferOrderApi = withPageLevelCall(
    PageTransferOrder,
    ({ toId }) => api.getTo(toId)
  );
  const PageIntakeListApi = withPageLevelCall(PageIntakes, () =>
    api.getLatestIntakes()
  );
  const PageIntakeApi = withPageLevelCall(PageIntake, ({ intakeId }) =>
    api.getIntake(intakeId)
  );
  const PagePatientsApi = withPageLevelCall(PagePatients, () =>
    api.getPatients()
  );
  const PagePatientApi = withPageLevelCall(PagePatient, ({ eocId }) =>
    api.getPatient(eocId)
  );

  const PagePrescriberOnboardingApi = withPageLevelCall(
    PagePrescriberOnboarding,
    ({ rxerobId }) => api.getPrescriberOnboard(rxerobId)
  );

  const PagePrescriberOnboardingsApi = withPageLevelCall(
    PagePrescriberOnboardings,
    () => api.getPrescriberOnboards()
  );
  const PageOrgsApi = withPageLevelCall(PageOrgs, () => api.getOrgs());
  const PageOrgApi = withPageLevelCall(PageOrg, ({ orgId }) =>
    api.getOrg(orgId)
  );

  const PageRxApi = withPageLevelCall(PageRx, ({ rxId }) => api.getRx(rxId));
  const PageOrderApi = withPageLevelCall(PageOrder, ({ ordId }) =>
    api.getOrder(ordId)
  );

  const PagePaymentApi = withPageLevelCall(PagePayment, ({ pmtId }) =>
    api.getPayment(pmtId)
  );

  const PageTreatmentAttachmentsApi = withPageLevelCall(
    PageTreatmentAttachments,
    ({ treatId }) => api.treatmentAllAttachments(treatId)
  );

  const PagePrescriberApi = withPageLevelCall(PagePrescriber, ({ npi }) =>
    api.getPrescriber(npi)
  );

  const PageUnitApi = withPageLevelCall(PageUnit, ({ unitId }) =>
    api.getUnit(unitId)
  );

  const PageCommissionDatesApi = withPageLevelCall(PageCommissionDates, () =>
    api.listCommDates()
  );

  const PageCommissionsApi = withPageLevelCall(
    PageCommissions,
    ({ payoutDate }) => api.unsweptCommissions(payoutDate)
  );

  const PageReportApi = withPageLevelCall(PageReports, () => api.listReports());

  const PagePracticeEngagementEmailGlobalsApi = withPageLevelCall(
    PagePracticeEngagementEmailGlobals,
    () => api.practiceEngagementGlobals()
  );

  return (
    <Container
      fixed
      sx={{ width: 1180, minWidth: 1180, maxWidth: 1180 }}
      disableGutters
    >
      <TopBar toggleThemeMode={toggleThemeMode} api={api} />
      <Grid container columns={18} columnSpacing={3}>
        <Grid item xs={4}>
          <Box sx={{ pt: 2 }}>
            <SideNav />
          </Box>
        </Grid>
        <Grid item xs={14} sx={{ pt: 2 }}>
          <Routes>
            <Route element={<PageHome api={api} />} path="dashboard" />
            <Route element={<PageWorkspace api={api} />} path="workspace" />
            <Route element={<PageReportApi api={api} />} path="reports" />
            <Route element={<PageSettings api={api} />} path="settings" />
            <Route element={<PageMassTexter api={api} />} path="txtr" />
            <Route path="agencies">
              <Route index element={<PageAgenciesApi api={api} />} />
              <Route
                Component={(params) => (
                  <PageAgencyApi api={api} key={new Date()} {...params} />
                )}
                path=":agencyId"
              />
              <Route
                element={<PageAgencyItemHistoryApi api={api} />}
                path=":agencyId/items/:itemId"
              />
            </Route>
            <Route path="reps">
              <Route index element={<PageRepsApi />} />
              <Route
                Component={(params) => (
                  <PageRepApi key={new Date()} api={api} {...params} />
                )}
                path=":repId"
              />
            </Route>
            <Route path="practices">
              <Route index element={<PagePracticesApi api={api} />} />
              <Route
                Component={(params) => (
                  <PagePracticeApi key={new Date()} api={api} {...params} />
                )}
                path=":practiceId"
              />
              <Route
                Component={(params) => (
                  <PagePracticeEngagementEmailGlobalsApi
                    key={new Date()}
                    api={api}
                    {...params}
                  />
                )}
                path="email"
              />
            </Route>
            <Route path="prescribers">
              <Route
                path=":npi"
                Component={(params) => (
                  <PagePrescriberApi key={new Date()} api={api} {...params} />
                )}
              />
              <Route path="onboarding">
                <Route index element={<PagePrescriberOnboardingsApi />} />
                <Route
                  path=":rxerobId"
                  element={<PagePrescriberOnboardingApi api={api} />}
                />
              </Route>
            </Route>
            <Route path="intake">
              <Route index element={<PageIntakeListApi api={api} />} />
              <Route
                path=":intakeId"
                Component={(params) => (
                  <PageIntakeApi api={api} key={new Date()} {...params} />
                )}
              />
            </Route>
            <Route element={<PageDebug api={api} />} path="debug" />
            <Route path="purchase-orders">
              <Route index element={<PagePurchaseOrders api={api} />} />
              <Route
                path=":poId"
                element={<PagePurchaseOrderApi api={api} />}
              />
            </Route>
            <Route path="patients">
              <Route index element={<PagePatientsApi api={api} />} />
              <Route
                path=":eocId"
                Component={(params) => (
                  <PagePatientApi key={new Date()} api={api} {...params} />
                )}
              />
            </Route>
            <Route
              path="treatments/:treatId"
              element={<PageTreatments api={api} />}
            />
            <Route
              path="treatments/:treatId/attachments"
              element={<PageTreatmentAttachmentsApi api={api} />}
            />
            <Route path="notifications">
              <Route index element={<PageNotificationList api={api} />} />
            </Route>
            <Route path="tasks">
              <Route index element={<PageTickets api={api} />} />
              <Route path=":taskId" element={<PageTicket api={api} />} />
            </Route>
            <Route
              path="service-cycle/:srvcId"
              element={<PageServiceCycleApi api={api} />}
            />
            <Route path="transfer-orders">
              <Route index element={<PageTransferOrdersApi />} />
              <Route path="new" element={<PageTransferOrderNew api={api} />} />
              <Route
                path=":toId"
                element={<PageTransferOrderApi api={api} />}
              />
            </Route>
            <Route path="orgs">
              <Route index element={<PageOrgsApi api={api} />} />
              <Route path=":orgId" element={<PageOrgApi api={api} />} />
            </Route>
            <Route path="rxs">
              <Route path=":rxId" element={<PageRxApi api={api} />} />
            </Route>
            <Route path="orders">
              <Route
                path=":ordId"
                Component={(params) => (
                  <PageOrderApi key={new Date()} api={api} {...params} />
                )}
              />
            </Route>
            <Route path="payments">
              <Route index element={<PagePayments api={api} />} />
              <Route path=":pmtId" element={<PagePaymentApi api={api} />} />
            </Route>
            <Route path="units">
              <Route index element={<PagePatientsApi api={api} />} />
              <Route
                path=":unitId"
                Component={(params) => (
                  <PageUnitApi key={new Date()} api={api} {...params} />
                )}
              />
            </Route>
            <Route
              path="commission-dates"
              element={<PageCommissionDatesApi api={api} />}
            />
            <Route
              path="commissions/:payoutDate"
              element={<PageCommissionsApi api={api} />}
            />
            <Route path="test" element={<PageTest />} />
            <Route element={<PageNotFound />} path="*" />
          </Routes>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LayoutMain;
