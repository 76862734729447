import { useNavigate } from "react-router-dom";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import KeyValueList from "../../PagePartials/KeyValueList";
import useDialogState from "../../../HOC/useDialogState";
import { maxGenToStr } from "../../../utility";
import DoubleLabelProgress from "../../StandardComponents/DoubleLabelProgress";
import ComplexSplitButton from "../../StandardComponents/ComplexSplitButton";
import RxCard from "./RxCard";
import SrvcCard from "./SrvcCard";
import HardCopyPicAgncFormDialog from "./HardCopyPicAgncFormDialog";
import ViewListIcon from "@mui/icons-material/ViewList";
import SwapUnitFormDialog from "./SwapUnitFormDialog";
import HardCopyPicDSFormDialog from "./HardCopyPicDSFormDialog";
import SevenDayCallDialog from "./SevenDayCallDialog";
import FitableAlert from "./FitableAlert";
import DaisyBillIcon from "../../StandardComponents/Icons/DaisyBillIcon";
import { daisyLinkToInjury } from "../../../daisy";

const progressCalc = (treat) => {
  const maxDays = Math.max(
    treat.authed_days,
    treat.prescribed_days,
    treat.serviced_days
  );

  return {
    prescribed: (treat.prescribed_days / maxDays) * 100,
    authorized: (treat.authed_days / maxDays) * 100,
    serviced: (treat.serviced_days / maxDays) * 100,
  };
};

const shouldShowSevenDayCallOpt = (serviceStartDate, sevenDayCompletedDate) => {
  if (serviceStartDate && !sevenDayCompletedDate) return true;
  return false;
};

const shouldShowHcAgncPicOpt = (serviceChannel, picCompleteOn) => {
  if (null !== picCompleteOn) return false;
  return serviceChannel === "agency";
};

const shouldShowDSPicOpt = (serviceChannel, picCompleteOn) => {
  if (null !== picCompleteOn) return false;
  return serviceChannel === "drop-ship";
};

const shouldShowSwapOpt = (latestSrvc) => {
  if (latestSrvc.service_channel === "agency") {
    return !!latestSrvc.fit_on && !latestSrvc.returned_on;
  }

  return !!latestSrvc.shipped_on && !latestSrvc.picked_up_on;
};

const DaisyBillInjuryIcon = ({ daisyId, onClick }) => {
  if (!daisyId)
    return (
      <IconButton onClick={onClick}>
        <DaisyBillIcon disabled />
      </IconButton>
    );

  return (
    <Tooltip title={`#${daisyId}`}>
      <IconButton
        component="a"
        href={daisyLinkToInjury(daisyId)}
        target="_blank"
      >
        <DaisyBillIcon />
      </IconButton>
    </Tooltip>
  );
};

const TreatmentSection = ({ treat, api, refresh, pageData }) => {
  // console.log("treat section", treat);
  const navigate = useNavigate();
  const progress = progressCalc(treat);
  const attachLink = `/treatments/${treat.id}/attachments`;
  const hasBeenFit = treat.srvcs.reduce(
    (acc, cur) => acc || !!cur.fit_on,
    false
  );

  const {
    open: openSevenDayCall,
    closeFn: handleCloseSevenDayCall,
    openFn: handleOpenSevenDayCall,
  } = useDialogState();
  const {
    open: openHcAgncPic,
    closeFn: handleCloseHcAgncPic,
    openFn: handleOpenHcAgncPic,
  } = useDialogState();
  const {
    open: openHcDSPic,
    closeFn: handleCloseHcDSPic,
    openFn: handleOpenHcDSPic,
  } = useDialogState();
  const {
    open: openSwap,
    closeFn: handleCloseSwap,
    openFn: handleOpenSwap,
  } = useDialogState();

  const showSevenDayCallOption = shouldShowSevenDayCallOpt(
    treat.service_start_on,
    treat.seven_day_completed_on
  );
  const showHcPicOption = shouldShowHcAgncPicOpt(
    treat.srvcs[0].service_channel,
    treat.pic_complete_on
  );
  const showDsPicOption = shouldShowDSPicOpt(
    treat.srvcs[0].service_channel,
    treat.pic_complete_on
  );
  const showUnitSwapOption = shouldShowSwapOpt(
    treat.srvcs[treat.srvcs.length - 1]
  );

  const buttonOpts = [];

  if (showSevenDayCallOption)
    buttonOpts.push({ label: "7-Day Call", onClick: handleOpenSevenDayCall });
  if (showHcPicOption)
    buttonOpts.push({ label: "Hard Copy PIC", onClick: handleOpenHcAgncPic });
  if (showDsPicOption)
    buttonOpts.push({ label: "Hard Copy PIC", onClick: handleOpenHcDSPic });
  if (showUnitSwapOption)
    buttonOpts.push({ label: "Swap Unit", onClick: handleOpenSwap });

  const shippedWithoutContact =
    treat.acked_on === null && treat.srvcs[0].pref_arrive_on !== null;

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader
            title={`Treatment: ${treat.therapy.toUpperCase()}`}
            subheader={maxGenToStr(treat.rxs.length - 1)}
            action={
              <Stack direction="row" spacing={1}>
                <ComplexSplitButton options={buttonOpts} />
                <DaisyBillInjuryIcon
                  daisyId={treat.daisy_id}
                  onClick={() => {}}
                />
                <Tooltip title="View all attachment files">
                  <IconButton onClick={() => navigate(attachLink)}>
                    <ViewListIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            }
          />
          <CardContent>
            <Stack spacing={2}>
              {!hasBeenFit && (
                <FitableAlert
                  fitable={treat.fitable}
                  fitable_memo={treat.fitable_memo}
                  fitable_until={treat.fitable_until}
                />
              )}
              {treat.pic_complete_on === null && (
                <Alert severity="warning">
                  PIC not received for this patient!
                </Alert>
              )}
              {shippedWithoutContact && (
                <Alert severity="error">
                  This patient has not been contacted but we are moving forward
                  with shipping them a unit anyway!
                </Alert>
              )}
              <DoubleLabelProgress
                leftLabel="Prescribed Days"
                value={progress.prescribed}
                color="primary"
                rightLabel={`${treat.prescribed_days} days`}
              />
              <DoubleLabelProgress
                leftLabel="Authorized Days"
                value={progress.authorized}
                color="warning"
                rightLabel={`${treat.authed_days} days`}
              />
              <DoubleLabelProgress
                leftLabel="Serviced Days"
                value={progress.serviced}
                color="success"
                rightLabel={`${treat.serviced_days} days`}
              />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2} direction="column">
          {treat.rxs.map((rx) => (
            <RxCard
              key={rx.id}
              rx={rx}
              eocRef={pageData}
              api={api}
              refresh={refresh}
            />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2} direction="column">
          {treat.srvcs.map((srvc) => (
            <SrvcCard
              eocRef={pageData}
              treatRef={treat}
              srvc={srvc}
              key={srvc.id}
              api={api}
              refresh={refresh}
            />
          ))}
        </Stack>
      </Grid>
      <HardCopyPicAgncFormDialog
        agencyId={treat.rxs[0].agency_id}
        itemId={treat.item_id}
        open={openHcAgncPic}
        onClose={handleCloseHcAgncPic}
        api={api}
        refresh={refresh}
        srvcId={treat.srvcs[0].id}
        treatId={treat.id}
        rxSalesRepId={treat.rxs[0].sales_rep_id}
        rxServiceRepId={treat.rxs[0].service_rep_id}
      />
      <HardCopyPicDSFormDialog
        api={api}
        refresh={refresh}
        treatId={treat.id}
        open={openHcDSPic}
        onClose={handleCloseHcDSPic}
      />
      <SwapUnitFormDialog
        open={openSwap}
        onClose={handleCloseSwap}
        agencyId={treat.rxs[treat.rxs.length - 1].agency_id}
        srvc={treat.srvcs[treat.srvcs.length - 1]}
        api={api}
        refresh={refresh}
      />
      <SevenDayCallDialog
        open={openSevenDayCall}
        onClose={handleCloseSevenDayCall}
        api={api}
        refresh={refresh}
        treatId={treat.id}
      />
    </>
  );
};

export default TreatmentSection;
