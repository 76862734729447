import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
} from "@mui/material";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import PrescriberTabPanelMain from "./PagePrescriber/PrescriberTabPanelMain";
import PrescriberTabPanelEnrollments from "./PagePrescriber/PrescriberTabPanelEnrollments";
import PrescriberTabPanelPatients from "./PagePrescriber/PrescriberTabPanelPatients";
import PrescriberTabPanelProduction from "./PagePrescriber/PrescriberTabPanelProduction";
import ApiDataContext from "../../ApiDataContext";
import TicketContextMenu from "../PagePartials/Tickets/TicketContextMenu";

const PagePrescriber = ({ pageData, api, refresh }) => {
  const { npiBlacklist } = useContext(ApiDataContext);
  const { state: navState } = useLocation();
  const { npi: urlId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(navState?.activeTab || "main");

  useEffect(() => {
    if (pageData.npi !== urlId) {
      navigate(`/prescribers/${pageData.npi}`, { replace: true });
    }
  }, [navigate, pageData.npi, urlId]);

  const handleTabChange = (e, newTab) => {
    setActiveTab(newTab);
  };

  const cardTitle = [
    pageData.name_first,
    pageData.name_last,
    pageData.credential,
  ]
    .filter(Boolean)
    .join(" ");
  const cardSubheader = `NPI: ${pageData.npi}`;

  const isBlacklisted = npiBlacklist.includes(pageData.npi);

  return (
    <>
      <BackLinkHeader
        to={`/practices/${pageData.practice_id}`}
        title="Back to Practice"
      />
      <Card variant="outlined" sx={{ mb: 3 }}>
        <CardHeader
          title={cardTitle}
          subheader={cardSubheader}
          action={
            <TicketContextMenu
              api={api}
              context={{
                type: "prescribers",
                ident: pageData.id,
                label: cardTitle,
              }}
            />
          }
        />
        {isBlacklisted && (
          <CardContent>
            <Alert severity="error">
              <AlertTitle>Prescriber On NPI Blacklist</AlertTitle>This
              prescriber is on our "NPI Blacklist" meaning they are marked as an
              active ORS prescriber. We <strong>may not</strong> process the
              script for WRS while this prescriber is an active ORS prescriber.
            </Alert>
          </CardContent>
        )}
      </Card>

      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab index={0} value="main" label="Main" />
        <Tab
          index={1}
          value="enrollments"
          label="Enrollments"
          programs={pageData.programs}
        />
        <Tab index={2} value="patients" label="Scripts" />
        <Tab index={3} value="production" label="Production" />
      </Tabs>

      <Box sx={{ my: 3 }} />

      <PrescriberTabPanelMain
        activeTab={activeTab}
        api={api}
        pageData={pageData}
        refresh={refresh}
      />
      <PrescriberTabPanelEnrollments
        activeTab={activeTab}
        programs={pageData.programs}
        npi={pageData.npi}
        api={api}
        refresh={refresh}
      />
      <PrescriberTabPanelPatients
        activeTab={activeTab}
        patients={pageData.patients}
      />
      <PrescriberTabPanelProduction
        activeTab={activeTab}
        api={api}
        prescriberId={pageData.id}
      />
    </>
  );
};

export default PagePrescriber;
