import { useEffect, useReducer, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import redPanda from "../api";
import { ApiDataProvider } from "../ApiDataContext";
import LayoutMain from "./Layouts/LayoutMain";
import LayoutLogin from "./Layouts/LayoutLogin";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import { blue, blueGrey, grey, red, yellow } from "@mui/material/colors";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";

const setApiGlobal = (curState, toUpdate) => ({ ...curState, ...toUpdate });

const App = ({ initialThemeMode }) => {
  const {
    error,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const [api, setApi] = useState(null);
  const [globalApiLoaded, setGlobalApiLoaded] = useState(false);
  const [loadError, setLoadError] = useState("");
  const [apiGlobals, updateApiGlobals] = useReducer(setApiGlobal, {});
  const [themeMode, setThemeMode] = useState(initialThemeMode);
  const [logoutCountdown, setLogoutCountdown] = useState(-1);

  useEffect(() => {
    if (logoutCountdown < 0) return;
    if (logoutCountdown === 0) logout();
    const timerHandle = setTimeout(() => setLogoutCountdown((c) => c - 1), 980);
    return () => clearTimeout(timerHandle);
  }, [logoutCountdown, logout]);

  const toggleThemeMode = () =>
    setThemeMode((prev) => (prev === "light" ? "dark" : "light"));

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((res) => {
          const newApi = redPanda(res);
          setApi(newApi);
        })
        .catch((err) => {
          setLoadError(err.message);
        });
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    if (!api || globalApiLoaded) {
      return;
    }

    Promise.all([
      api.getSalesAreas(),
      api.acManufacturers(),
      api.getItems(),
      api.getMe(),
      api.npiBlacklist(),
    ])
      .then((resp) => {
        updateApiGlobals({
          api: api,
          salesAreas: resp[0].data,
          mfgrs: resp[1].data,
          items: resp[2].data,
          me: resp[3].data,
          npiBlacklist: resp[4].data,
          fullReload: () => setGlobalApiLoaded(false),
        });
        setGlobalApiLoaded(true);
        if (!resp[3].data.perms.includes("rp:login")) {
          setLogoutCountdown(4);
        }
      })
      .catch((err) => {
        setLoadError(err.message);
      });
  }, [api, globalApiLoaded]);

  useEffect(() => {
    localStorage.setItem("themeMode", themeMode);
  }, [themeMode]);

  if (isLoading) {
    return <Typography>Auth0 loading...</Typography>;
  }

  if (error) {
    return <Typography>Auth0 error: {error.message}</Typography>;
  }

  if (!isAuthenticated) {
    return <LayoutLogin handleLogin={loginWithRedirect} />;
  }

  if (loadError) {
    return (
      <Typography>There was an error loading the app: {loadError}</Typography>
    );
  }

  if (!api || !globalApiLoaded) {
    return <Typography>App loading...</Typography>;
  }

  if (!apiGlobals.me.perms.includes("rp:login")) {
    return (
      <Typography>
        Permission denied! Logging out in {logoutCountdown}...
      </Typography>
    );
  }

  const theme = createTheme({
    palette: {
      mode: themeMode,
      badgeGrey: {
        main: grey["A100"],
      },
      bgError: themeMode === "light" ? red[50] : "#261412",
      bgCaution: themeMode === "light" ? yellow[50] : "#3A2915",
      bgGrey: themeMode === "light" ? grey["A100"] : grey["A700"],
      bgBlue: themeMode === "light" ? blue[50] : blueGrey[900],
    },
    background: {
      construction: themeMode === "light" ? "#fafafa" : "#212121",
    },
    typography: {
      fontFamily:
        '"Twemoji Country Flags", "Roboto", "Helvetica", "Arial", sans-serif',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <BrowserRouter>
        <ScrollToTop />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ApiDataProvider value={apiGlobals}>
            <LayoutMain api={api} toggleThemeMode={toggleThemeMode} />
          </ApiDataProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
