const buildApiUrl = (path, params) => {
  let searchParams = "";
  if (params) {
    const p = new URLSearchParams();
    Object.entries(params).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        v.forEach((subv) => p.append(k, subv));
      } else {
        p.append(k, v);
      }
    });
    searchParams = p.toString();
  }
  const pathJoined = ["", ...path].map((p) => encodeURIComponent(p)).join("/");
  const url = new URL(
    `${pathJoined}${params ? "?" : ""}${searchParams}`,
    process.env.REACT_APP_API_BASE
  );
  return url.toString();
};

const buildOpts = (method, token, body) => {
  const opts = {
    method,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  if (body) {
    opts.body = JSON.stringify(body);
    opts.headers["Content-Type"] = "application/json";
  }

  return opts;
};

const redPanda = (token) => {
  const simpleGet = async (urlPath, params) => {
    const opts = buildOpts("GET", token);
    const url = buildApiUrl(urlPath, params);
    const resp = await fetch(url, opts);
    return resp.json();
  };

  const simpleSubmit = async (urlPath, data, method) => {
    const opts = buildOpts(method, token, data);
    const url = buildApiUrl(urlPath);
    const resp = await fetch(url, opts);
    if (!resp.ok) {
      const body = await resp.json();
      throw new Error(body.message);
    }
    return resp.json();
  };

  const simplePost = (urlPath, data) => simpleSubmit(urlPath, data, "POST");

  const simplePut = (urlPath, data) => simpleSubmit(urlPath, data, "PUT");

  const simpleDelete = async (urlPath) => {
    const opts = buildOpts("DELETE", token);
    const url = buildApiUrl(urlPath);
    const resp = await fetch(url, opts);
    return resp.json();
  };

  const getToken = () => token;

  const getSalesAreas = () => simpleGet(["sales-areas"]);
  const getAgencies = () => simpleGet(["agencies"]);
  const getAgency = (id) => simpleGet(["agencies", id]);
  const newAgency = (agency) => simplePost(["agencies"], agency);
  const newRep = (rep) => simplePost(["reps"], rep);
  const getReps = () => simpleGet(["reps"]);
  const getRep = (repId) => simpleGet(["reps", repId]);
  const newPractice = (practice) => simplePost(["practices"], practice);
  const getPractices = () => simpleGet(["practices"]);
  const getPractice = (practiceId) => simpleGet(["practices", practiceId]);
  const updateLocation = (locId, loc) => simplePut(["locations", locId], loc);
  const addLocation = (practiceId, loc) =>
    simplePost(["practices", practiceId, "locations"], loc);
  const setLocationPrimary = (locationId) =>
    simplePut(["locations", locationId, "make-primary"]);
  const updatePractice = (practiceId, practice) =>
    simplePut(["practices", practiceId], practice);
  const npiSearch = (npi) => simplePost(["npi-search"], { npi });
  const npiGet = (npi) => simpleGet(["npi", npi]);
  const addPrescriber = (prescriber) => simplePost(["prescribers"], prescriber);
  const updatePrescriber = (npi, prescriber) =>
    simplePut(["prescribers", npi], prescriber);
  const acPractices = () => simpleGet(["ac", "practices"]);
  const acPLocs = (pracId) => simpleGet(["ac", "practices", pracId, "ploc"]);
  const acPConts = (pracId) => simpleGet(["ac", "practices", pracId, "pcont"]);
  const acReps = () => simpleGet(["ac", "reps"]);
  const acIcd10 = () => simpleGet(["ac", "icd10"]);
  const acAgencies = () => simpleGet(["ac", "agencies"]);
  const acManufacturers = () => simpleGet(["ac", "mfgrs"]);
  const acAvailableSerialsByItem = (agencyId, itemId) =>
    simpleGet(["ac", "agencies", agencyId, "serials-avail", itemId]);
  const acAvailableWarehouseSerialsByItem = (itemId) =>
    simpleGet(["ac", "warehouse", itemId]);
  const icd10Get = (code) => simpleGet(["icd10", code]);
  const icd10Search = (code) => simplePost(["icd10-search"], { code });
  const newContact = (pracId, cont) =>
    simplePost(["practices", pracId, "contacts"], cont);
  const updateContact = (contId, cont) => simplePut(["contacts", contId], cont);
  const updateAttachment = (attachId, newMeta) =>
    simplePut(["attachments", "meta", attachId], newMeta);
  const deleteAttachment = (attachId) =>
    simpleDelete(["attachments", "meta", attachId]);
  const newAgencyLoc = (agencyId, location) =>
    simplePost(["agencies", agencyId, "locations"], location);
  const setAgencyAps = (agencyId, data) =>
    simplePost(["agencies", agencyId, "aps"], data);
  const getItems = () => simpleGet(["inv", "items"]);
  const addPo = (po) => simplePost(["inv", "po"], po);
  const getPos = (filters) => simpleGet(["inv", "po"], filters);
  const getPo = (poId) => simpleGet(["inv", "po", poId]);
  const poSetNotes = (poId, notes) =>
    simplePut(["inv", "po", poId, "notes"], { notes });
  const poSetLines = (poId, lines) =>
    simplePut(["inv", "po", poId, "lines"], { lines });
  const approvePo = (poId) => simplePut(["inv", "po", poId, "approve"]);
  const deletePo = (poId) => simpleDelete(["inv", "po", poId]);
  const cancelPo = (poId) => simplePut(["inv", "po", poId, "cancel"]);
  const receivePo = (poId, quantities) =>
    simplePut(["inv", "po", poId, "receive"], quantities);
  const invSummaryForAgency = (agencyId) =>
    simpleGet(["agencies", agencyId, "inv-summary"]);
  const addTo = (to) => simplePost(["inv", "txo"], to);
  const getTos = () => simpleGet(["inv", "txo"]);
  const getTo = (toId) => simpleGet(["inv", "txo", toId]);
  const approveTo = (toId) => simplePut(["inv", "txo", toId, "approve"]);
  const cancelTo = (toId) => simpleDelete(["inv", "txo", toId]);
  const deleteIntake = (intakeId) => simpleDelete(["intake", intakeId]);
  const assocSerialWithToLine = (toId, toLineId, serialId) =>
    simplePut(["inv", "txo", toId, "lines", toLineId, "assoc"], { serialId });
  const completeTo = (toId) => simplePut(["inv", "txo", toId, "complete"]);
  const acPrescribers = () => simpleGet(["ac", "prescribers"]);
  const npiBlacklist = () => simpleGet(["npi-blacklist"]);
  const newIntake = (patient) => simplePost(["intake"], patient);
  const getLatestIntakes = () => simpleGet(["intake", "latest"]);
  const getIntake = (intakeId) => simpleGet(["intake", intakeId]);
  const acPayors = (orgId) => simpleGet(["ac", "orgs", orgId, "payors"]);
  const acPackableConsumables = (treatId, agencyId) =>
    simpleGet(["ac", "packable"], { treatId, agencyId: agencyId ?? "" });
  const updateIntake = (intakeId, payload) =>
    simplePut(["intake", intakeId], payload);
  const rejectIntake = (intakeId, payload) =>
    simplePut(["intake", intakeId, "reject"], payload);
  const acceptIntake = (intakeId) => simplePut(["intake", intakeId, "accept"]);
  const getPatients = () => simpleGet(["patients"]);
  const getPatient = (eocId) => simpleGet(["patients", eocId]);
  const getPrescriberOnboards = () => simpleGet(["prescribers", "onboarding"]);
  const getPrescriberOnboard = (rxerobId) =>
    simpleGet(["prescribers", "onboarding", rxerobId]);
  const filePrescriber = (rxerobId, payload) =>
    simplePost(["prescribers", "onboarding", rxerobId, "file"], payload);
  const ptnUpdateDemos = (eocId, payload) =>
    simplePut(["patients", eocId, "demos"], payload);
  const ptnUpdateMemo = (eocId, payload) =>
    simplePut(["patients", eocId, "memo"], payload);
  const srvcSetAckedOn = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "ack"], payload);
  const srvcSetPrefArrival = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "pref-arrive"], payload);
  const srvcSetPrefShip = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "pref-ship"], payload);
  const srvcSetPacked = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "pack"], payload);
  const srvcSetShipped = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "ship"], payload);
  const srvcSetArrived = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "arrive"], payload);
  const srvcSetFit = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "fit"], payload);
  const srvcSetStart = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "start"], payload);
  const srvcSetEnd = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "end"], payload);
  const srvcSetPrefPickup = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "pref-pickup"], payload);
  const srvcSetPickup = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "pickup"], payload);
  const srvcSetReturned = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "return"], payload);
  const srvcSetReprocessed = (srvcId, payload) =>
    simplePut(["srvc", srvcId, "reprocess"], payload);
  const getServiceCycle = (srvcId) => simpleGet(["srvc", srvcId]);
  const getOrg = (orgId) => simpleGet(["orgs", orgId]);
  const getOrgs = () => simpleGet(["orgs"]);
  const addOrg = (data) => simplePost(["orgs"], data);
  const addPayor = (orgId, data) => simplePost(["orgs", orgId, "payors"], data);
  const getMe = () => simpleGet(["users", "me"]);
  const acUsers = () => simpleGet(["ac", "users"]);
  const acUsersExpanded = () => simpleGet(["ac", "users-expanded"]);
  const addContact = (orgId, data) =>
    simplePost(["orgs", orgId, "contacts"], data);
  const acInsConts = () => simpleGet(["ac", "ins-contacts"]);
  const getBucketData = (bucketName) => simpleGet(["buckets", bucketName]);
  const acPayorOrgs = () => simpleGet(["ac", "orgs"]);
  const getRx = (rxId) => simpleGet(["rxs", rxId]);
  const markOrderVerified = (orderId) =>
    simplePut(["orders", orderId, "verify"]);
  const assignGuides = (eocId, guides) =>
    simplePut(["patients", eocId, "assign"], guides);
  const getOrder = (orderId) => simpleGet(["orders", orderId]);
  const setOrderStatus = (orderId, payload) =>
    simplePut(["orders", orderId, "status"], payload);
  const getOrderLinesById = (orderId) =>
    simpleGet(["orders", orderId, "lines"]);
  const commitOrderLine = (orderId, payload) =>
    simplePut(["orders", orderId, "lines"], payload);
  const delOrderLine = (olId) => simpleDelete(["order-lines", olId]);
  const setPayor = (eocId, payorId) =>
    simplePost(["patients", eocId, "primary-payor"], { payor_id: payorId });
  const extendTreatment = (treatId, data) =>
    simplePost(["treatments", treatId, "extend"], data);
  const ptnExtResponse = (rxId, data) =>
    simplePut(["rxs", rxId, "ptn-ext"], data);
  const pracExtResponse = (rxId, data) =>
    simplePut(["rxs", rxId, "prac-ext"], data);
  const acLmns = (therapy) => simpleGet(["ac", "lmns", therapy]);
  const genBill = (orderId, format, poNumber) =>
    simplePost(["orders", orderId, "gen-bill"], {
      format,
      purchase_order: poNumber,
    });
  const getBillUrl = (billId) => simpleGet(["bills", billId, "url"]);
  const genLabel = (srvcId, payload) =>
    simplePost(["srvc", srvcId, "gen-label"], payload);
  const updateLabel = (srvcId, labelId) =>
    simplePut(["srvc", srvcId, "update-tracking", labelId]);
  const getPayments = (params) => simpleGet(["payments"], params);
  const acAllPayors = () => simpleGet(["ac", "payors-all"]);
  const createPayment = (payload) => simplePost(["payments"], payload);
  const getPayment = (pmtId) => simpleGet(["payments2", pmtId]);
  const searchOrders = (payload) => simpleGet(["orders"], payload);
  const allocPayment = (pmtId, payload) =>
    simplePut(["payments", pmtId, "allocations"], payload);
  const agencySetGuides = (agencyId, payload) =>
    simplePut(["agencies", agencyId, "assign-guides"], payload);
  const agencySetCommissionPlans = (agencyId, payload) =>
    simplePut(["agencies", agencyId, "commission-plans"], payload);
  const genRxDoc = (rxId) => simplePost(["rxs", rxId, "doc"]);
  const treatmentAllAttachments = (treatId) =>
    simpleGet(["treatments", treatId, "all-attachments"]);
  const getPrescriber = (npi) => simpleGet(["prescribers", npi]);
  const updatePrescNotes = (npi, payload) =>
    simplePut(["prescribers", npi, "set-notes"], payload);
  const updatePrescSettings = (npi, payload) =>
    simplePut(["prescribers", npi, "settings"], payload);
  const getSalesAttributionInsights = (start, end) =>
    simpleGet(["metrics", "sales", "attribution", start, end]);
  const search = (q) => simpleGet(["search"], { q });
  const setEnrollStatus = (npi, payload) =>
    simplePut(["prescribers", npi, "enrollment-approvals"], payload);
  const removeEnrollment = (npi, therapy, itemCode) =>
    simpleDelete(["prescribers", npi, "enrollments", therapy, itemCode]);
  const updateOrg = (orgId, payload) => simplePut(["orgs", orgId], payload);
  const updateDos = (eocId, payload) =>
    simplePut(["patients", eocId, "date-of-surgery"], payload);
  const acRepsByAgency = (agencyId) =>
    simpleGet(["ac", "agencies", agencyId, "reps"]);
  const acAttachByTreat = (treatId) =>
    simpleGet(["ac", "treatments", treatId, "attachments"]);
  const hcPic = (srvcId, payload) =>
    simplePost(["srvc", srvcId, "hardCopyPic"], payload);
  const hcDsPic = (treatId, attachmentId) =>
    simplePost(["treatments", treatId, "drop-ship-hc-pic"], {
      attachment_id: attachmentId,
    });
  const initiateUnitSwap = (treatId, payload) =>
    simplePost(["treatments", treatId, "unit-swap"], payload);
  const completeSevenDayCall = (treatId, payload) =>
    simplePost(["treatments", treatId, "seven-day-call"], payload);
  const updateTreatmentSites = (eocId, treatmentSites) =>
    simplePut(["patients", eocId, "treatment-sites"], treatmentSites);
  const updateIcd10s = (eocId, payload) =>
    simplePut(["patients", eocId, "icd-10"], payload);
  const addIntakeComment = (intakeId, payload) =>
    simplePost(["intake", intakeId, "comments"], payload);
  const addTreatmentComment = (treatId, payload) =>
    simplePost(["treatments", treatId, "comments"], payload);
  const addUnitComment = (invSerialId, payload) =>
    simplePost(["units", invSerialId, "comments"], payload);
  const addAgencyComment = (agencyId, payload) =>
    simplePost(["agencies", agencyId, "comments"], payload);
  const addTicketComment = (ticketId, payload) =>
    simplePost(["tasks", ticketId, "comments"], payload);
  const listIntakeComments = (intakeId) =>
    simpleGet(["intake", intakeId, "comments"]);
  const listTreatmentComments = (treatmentId) =>
    simpleGet(["treatments", treatmentId, "comments"]);
  const listUnitComments = (unitId) => simpleGet(["units", unitId, "comments"]);
  const listAgencyComments = (agencyId) =>
    simpleGet(["agencies", agencyId, "comments"]);
  const listTicketComments = (ticketId) =>
    simpleGet(["tasks", ticketId, "comments"]);
  const commentPin = (commentId) => simplePut(["comments", commentId, "pin"]);
  const commentUnpin = (commentId) =>
    simplePut(["comments", commentId, "unpin"]);
  const updatePayment = (paymentId, payload) =>
    simplePut(["payments", paymentId], payload);
  const listCommDates = () => simpleGet(["commissions", "dates"]);
  const setCommDate = (date) => simplePut(["commissions", "dates", date]);
  const deleteCommDate = (date) => simpleDelete(["commissions", "dates", date]);
  const unsweptCommissions = (date) =>
    simpleGet(["commissions", "unswept", date]);
  const sweepCommissions = (payload) =>
    simplePut(["commissions", "sweep"], payload);
  const lockCommDate = (date) =>
    simplePut(["commissions", "dates", date, "lock"]);
  const addCommissionBonus = (agencyId, payload) =>
    simplePost(["agencies", agencyId, "commission-incentive"], payload);
  const updateDateReceived = (rxId, date_received) =>
    simplePut(["rxs", rxId, "date-received"], { date_received });
  const bgData = (entity, ident, dayStart, dayEnd) =>
    simpleGet(["metrics", "bleeder-gainer"], {
      entity_ident: `${entity}-${ident}`,
      day_start: dayStart,
      day_end: dayEnd,
    });
  const repSetupUser = (repId) => simplePost(["reps", repId, "auth0"]);
  const phoneLog = (phone) => simpleGet(["zoom", "call-logs", phone]);
  const voidPayment = (pmtId) => simpleDelete(["payments", pmtId]);
  const massTextVerify = (rawTargets) =>
    simplePost(["txtr", "target-check"], { rawTargets });
  const sendText = (number, msg) =>
    simplePost(["txtr", "text"], { number, msg });
  const getAgencyItemHistory = (agencyId, itemId) =>
    simpleGet(["agencies", agencyId, "items", itemId]);
  const agencyTrueup = (agencyId, trueups) =>
    simplePost(["agencies", agencyId, "trueup"], trueups);

  // special because binary return!
  const getAttachment = async (attachId) => {
    const url = buildApiUrl(["attachments", "view", attachId]);
    const opts = buildOpts("GET", token);
    opts.headers.Accept = "image/*, application/pdf, x-application/zpl";
    const resp = await fetch(url, opts);
    return resp.blob();
  };

  const getSignature = async (sigKey) => {
    const url = buildApiUrl(["sigs", sigKey]);
    const opts = buildOpts("GET", token);
    opts.headers.Accept = "image/*";
    const resp = await fetch(url, opts);
    if (!resp.ok) {
      const body = await resp.json();
      throw new Error(body.message);
    }
    return resp.blob();
  };

  const getReport = (report, filters) =>
    simpleGet(["reports", report], filters);
  const listReports = () => simpleGet(["reports"]);

  const getCommissionReport = async (payoutDate) => {
    const url = buildApiUrl(["reports", "commissions", payoutDate]);
    const opts = buildOpts("GET", token);
    opts.headers.Accept = "text/*";
    const resp = await fetch(url, opts);

    if (!resp.ok) {
      const body = await resp.json();
      throw new Error(body.message);
    }
    return resp.blob();
  };

  const getUnit = (unitId) => simpleGet(["units", unitId]);
  const orderLineARIgnore = (olid) =>
    simplePut(["order-lines", olid, "ar-ignore"]);
  const orderSetARNote = (orderId, note) =>
    simplePut(["orders", orderId, "ar-note"], { note });
  const createPatientFeedback = (eocId, payload) =>
    simplePost(["patients", eocId, "feedback"], payload);
  const deleteFeedback = (fbId) => simpleDelete(["patient-feedback", fbId]);
  const practiceEngagementGlobals = () =>
    simpleGet(["practices", "engagement-email"]);
  const practiceEngagementGlobalsUpdate = (payload) =>
    simplePut(["practices", "engagement-email"], payload);

  const listTickets = () => {
    return Promise.resolve({ status: "ok", data: [] });
  };
  const ticketCreate = (payload) => simplePost(["tasks"], payload);
  const ticketSummaryCounts = () => simpleGet(["tasks", "counts"]);
  const ticketDashboardList = (category, range = null) => {
    if (range) {
      return simpleGet(["tasks", "dashboard"], {
        category,
        start: range[0],
        end: range[1],
      });
    }
    return simpleGet(["tasks", "dashboard"], { category });
  };
  const ticketContextList = (contextType, contextIdent) =>
    simpleGet(["tasks", "context", contextType, contextIdent]);
  const eocIdFromTreat = (treatId) =>
    simpleGet(["treatments", treatId, "eoc-lookup"]);
  const getTicket = (ticketId) => simpleGet(["tasks", ticketId]);
  const notificationPreviewList = () =>
    simpleGet(["tasks", "notification-preview"]);
  const markNotificationsRead = () => simplePost(["notifications", "read-all"]);
  const listAllNotifications = (amt) => simpleGet(["notifications"], { amt });
  const updateTicketDescription = (taskId, data) =>
    simplePut(["tasks", taskId, "description"], data);
  const updateTicketStatus = (taskId, status) =>
    simplePut(["tasks", taskId, "status"], { status });
  const updateTicketOwner = (taskId, userId) =>
    simplePut(["tasks", taskId, "owner"], { userId });
  const updateTicketAssignee = (taskId, userId) =>
    simplePut(["tasks", taskId, "assignee"], { userId });
  const addTicketCCUsers = (taskId, newCCList) =>
    simplePut(["tasks", taskId, "append-cc"], newCCList);
  const updateTicketFollowUp = (taskId, data) =>
    simplePut(["tasks", taskId, "follow-up"], data);
  const updateTicketCCMe = (taskId, data) =>
    simplePut(["tasks", taskId, "notify-me"], data);
  const updateUserAvatar = (img) => simplePut(["users", "me"], { img });
  const updateComment = (commentId, newCommentBody) =>
    simplePut(["comments", commentId, "body"], { new_comment: newCommentBody });
  const setExtensionOk = (orderId, extensionOk) =>
    simplePut(["orders", orderId, "ext-ok"], { ext_ok: extensionOk });
  const daisyPatientSearch = (first_name, last_name) =>
    simpleGet(["daisy", "patient-search"], { first_name, last_name });
  const daisyPatientLink = (eocId, daisyId) =>
    simplePut(["daisy", "patient-link"], { eoc_id: eocId, daisy_id: daisyId });

  return {
    daisyPatientLink,
    listTickets,
    getToken,
    getSalesAreas,
    getAgencies,
    getAgency,
    newAgency,
    newRep,
    getReps,
    getRep,
    newPractice,
    getPractices,
    getPractice,
    updateLocation,
    addLocation,
    setLocationPrimary,
    updatePractice,
    npiSearch,
    npiGet,
    addPrescriber,
    updatePrescriber,
    newContact,
    updateContact,
    icd10Get,
    icd10Search,
    acPractices,
    acPLocs,
    acPConts,
    acReps,
    acIcd10,
    acAgencies,
    acManufacturers,
    acPrescribers,
    npiBlacklist,
    acAvailableSerialsByItem,
    acAvailableWarehouseSerialsByItem,
    acPackableConsumables,
    getAttachment,
    updateAttachment,
    deleteAttachment,
    newAgencyLoc,
    setAgencyAps,
    getItems,
    addPo,
    getPos,
    getPo,
    poSetLines,
    approvePo,
    deletePo,
    cancelPo,
    receivePo,
    invSummaryForAgency,
    addTo,
    getTos,
    getTo,
    approveTo,
    cancelTo,
    assocSerialWithToLine,
    completeTo,
    newIntake,
    getLatestIntakes,
    getIntake,
    acPayors,
    deleteIntake,
    updateIntake,
    rejectIntake,
    acceptIntake,
    getPatients,
    getPatient,
    getPrescriberOnboards,
    getPrescriberOnboard,
    filePrescriber,
    ptnUpdateDemos,
    ptnUpdateMemo,
    srvcSetAckedOn,
    srvcSetPrefArrival,
    srvcSetPrefShip,
    srvcSetPacked,
    srvcSetShipped,
    getServiceCycle,
    srvcSetArrived,
    srvcSetFit,
    srvcSetStart,
    srvcSetEnd,
    srvcSetPrefPickup,
    srvcSetPickup,
    srvcSetReturned,
    srvcSetReprocessed,
    getOrg,
    getOrgs,
    addOrg,
    addPayor,
    getMe,
    acUsers,
    acUsersExpanded,
    addContact,
    acInsConts,
    getBucketData,
    acPayorOrgs,
    getRx,
    markOrderVerified,
    assignGuides,
    getOrder,
    setOrderStatus,
    getOrderLinesById,
    commitOrderLine,
    delOrderLine,
    setPayor,
    extendTreatment,
    ptnExtResponse,
    pracExtResponse,
    acLmns,
    genBill,
    getBillUrl,
    genLabel,
    updateLabel,
    getPayments,
    acAllPayors,
    createPayment,
    getPayment,
    searchOrders,
    allocPayment,
    agencySetGuides,
    agencySetCommissionPlans,
    genRxDoc,
    treatmentAllAttachments,
    getPrescriber,
    updatePrescNotes,
    updatePrescSettings,
    getSignature,
    getSalesAttributionInsights,
    search,
    setEnrollStatus,
    removeEnrollment,
    updateOrg,
    getReport,
    listReports,
    getCommissionReport,
    getUnit,
    updateDos,
    acRepsByAgency,
    acAttachByTreat,
    hcPic,
    initiateUnitSwap,
    hcDsPic,
    completeSevenDayCall,
    updateTreatmentSites,
    updateIcd10s,
    addIntakeComment,
    addTreatmentComment,
    addUnitComment,
    addAgencyComment,
    addTicketComment,
    listIntakeComments,
    listTreatmentComments,
    listUnitComments,
    listAgencyComments,
    listTicketComments,
    commentPin,
    commentUnpin,
    updatePayment,
    listCommDates,
    setCommDate,
    deleteCommDate,
    unsweptCommissions,
    sweepCommissions,
    lockCommDate,
    addCommissionBonus,
    updateDateReceived,
    bgData,
    repSetupUser,
    phoneLog,
    voidPayment,
    massTextVerify,
    sendText,
    poSetNotes,
    getAgencyItemHistory,
    agencyTrueup,
    orderLineARIgnore,
    orderSetARNote,
    createPatientFeedback,
    deleteFeedback,
    practiceEngagementGlobals,
    practiceEngagementGlobalsUpdate,
    ticketCreate,
    ticketSummaryCounts,
    ticketDashboardList,
    eocIdFromTreat,
    ticketContextList,
    getTicket,
    notificationPreviewList,
    markNotificationsRead,
    listAllNotifications,
    updateTicketDescription,
    updateTicketStatus,
    updateTicketOwner,
    updateTicketAssignee,
    addTicketCCUsers,
    updateTicketFollowUp,
    updateTicketCCMe,
    updateUserAvatar,
    updateComment,
    setExtensionOk,
    daisyPatientSearch,
  };
};

export default redPanda;
