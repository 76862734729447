import { useForm } from "react-hook-form";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import { Button, Stack } from "@mui/material";
import RHFUserPicker from "../../FormControlsRHF/RHFUserPicker";
import Grid2 from "@mui/material/Unstable_Grid2";
import RHFDateTimePicker from "../../FormControlsRHF/RHFDateTimePicker";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import { addDays } from "date-fns";

const genDefaultValues = () => {
  return {
    title: "",
    description: "",
    assignee_id: null,
    follow_up_on: null,
  };
};

/**
 * @param api
 * @param {function} onSuccess
 * @param {{
 *   type: string,
 *   ident: number
 * }|null} [context=null]
 * @param {function} [onClose=undefined]
 * @returns {JSX.Element}
 * @constructor
 */
const NewTicketForm = ({
  api,
  onSuccess,
  onClose = undefined,
  context = null,
}) => {
  const defaultValues = genDefaultValues();
  const { control, handleSubmit, reset, setError } = useForm({ defaultValues });

  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    () => {
      onSuccess();
      reset(defaultValues);
    }
  );

  const onSubmit = (data) => {
    const payload = {
      ...data,
      assignee_id: parseInt(data.assignee_id?.value),
      context,
    };
    apiWrapper(api.ticketCreate(payload));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid2 container columnSpacing={3}>
        {formErrState.hasErrors && (
          <Grid2 xs={12}>
            <FormLevelErrorAlert formErrStruct={formErrState} />
          </Grid2>
        )}
        <Grid2 xs={6}>
          <RHFUserPicker
            control={control}
            label="Assigned To"
            name="assignee_id"
            api={api}
          />
        </Grid2>
        <Grid2 xs={6}>
          <RHFDateTimePicker
            control={control}
            label="Follow Up / Due Date"
            name="follow_up_on"
            size="small"
            fullWidth
            pickerProps={{
              disablePast: true,
              maxDateTime: addDays(new Date(), 30),
            }}
          />
        </Grid2>
        <Grid2 xs={12}>
          <RHFTextField control={control} label="Title" name="title" />
        </Grid2>
        <Grid2 xs={12}>
          <RHFTextField
            multiline
            rows={8}
            label="Description"
            control={control}
            name="description"
          />
        </Grid2>
        <Grid2 xs={12}>
          <Stack direction="row-reverse" spacing={2}>
            <Button variant="contained" type="submit">
              Create Task
            </Button>
            {onClose && (
              <Button
                variant="contained"
                color="inherit"
                type="button"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            )}
          </Stack>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default NewTicketForm;
