import { Stack } from "@mui/material";
import BackLinkHeader from "../PagePartials/BackLinkHeader";
import SectionHeading from "../PagePartials/SectionHeading";
import RepHeaderCard from "./PageRep/RepHeaderCard";
import BleederGainerCard from "../StandardComponents/BleederGainerCard";
import TicketContextMenu from "../PagePartials/Tickets/TicketContextMenu";

const PageRep = ({ pageData, api, refresh }) => {
  const backLinkTitle = `Back to ${pageData.agency.name}`;
  const agencyUrl = `/agencies/${pageData.agency.id}`;

  return (
    <Stack direction="column" spacing={3}>
      <BackLinkHeader title={backLinkTitle} to={agencyUrl} />
      <SectionHeading
        headingLabel={pageData.name}
        buttonEl={
          <TicketContextMenu
            api={api}
            context={{
              type: "reps",
              ident: pageData.id,
              label: pageData.name,
            }}
          />
        }
      />
      <RepHeaderCard
        repId={pageData.id}
        repName={pageData.name}
        agencyId={pageData.agency.id}
        agencyName={pageData.agency.name}
        email={pageData.email}
        phone={pageData.phone}
        serviceChannel={pageData.service_channel}
        roles={pageData.roles}
        createdOn={pageData.created_on}
        user={pageData.user}
        serviceRep={pageData.service_rep}
        api={api}
        refresh={refresh}
      />
      <BleederGainerCard
        title="Production History"
        api={api}
        ident={pageData.id}
        entity="reps"
      />
    </Stack>
  );
};

export default PageRep;
