import { useReducer } from "react";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import StandardDialog from "../../StandardComponents/StandardDialog";
import LinkIcon from "@mui/icons-material/Link";

/** @typedef { "init" | "searching" | "results" | "selected" | "committing" | "committed" | "error" } DaisyPatientLinkDialogState */
/** @typedef { "search" | "search_r" | "select" | "unselect" | "commit" | "commit_r" | "reset" | "txt-fn" | "txt-ln" } DaisyPatientLinkDialogActions */
/**
 * @typedef {{
 *   daisy_id: number,
 *   first_name: string,
 *   last_name: string,
 *   date_of_birth: string,
 *   ssn: string,
 * }} DaisyPatientLinkDialogSearchResult
 */
/**
 * @typedef {{
 *   state: DaisyPatientLinkDialogState,
 *   searchResults: Array<DaisyPatientLinkDialogSearchResult>,
 *   selectedDaisyId: number|null,
 *   iptFirstName: string,
 *   iptLastName: string,
 *   errStr: string,
 * }} DaisyPatientLinkDialogUiState
 */

/**
 *
 * @param {Array<DaisyPatientLinkDialogSearchResult>} searchResults
 * @param {number|null} daisyId
 * @param onSelect
 */
const DaisyPatientList = ({ searchResults, daisyId, onSelect }) => {
  if (searchResults.length === 0)
    return <Alert severity="warning">No DaisyBill patients found.</Alert>;

  return (
    <>
      <Divider />
      <Typography>DaisyBill Patients:</Typography>
      <List dense sx={{ p: 0 }}>
        {searchResults.map((r) => {
          const checked = r.daisy_id === daisyId;
          return (
            <ListItem key={r.daisy_id}>
              <ListItemButton
                onClick={() =>
                  onSelect(daisyId === r.daisy_id ? null : r.daisy_id)
                }
              >
                <ListItemIcon>
                  <Checkbox
                    disableRipple
                    edge="start"
                    tabIndex={-1}
                    checked={checked}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`${r.first_name} ${r.last_name}`}
                  secondary={`Date of Birth: ${r.date_of_birth}; SSN: ${r.ssn}`}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

/**
 * Manages the state transition of this component
 *
 * @param {DaisyPatientLinkDialogUiState} state
 * @param {{
 *   transition: DaisyPatientLinkDialogActions,
 *   selectedDaisyId?: number|null,
 *   searchResults?: Array<DaisyPatientLinkDialogSearchResult>
 *   value?: string,
 *   nameFirst?: string,
 *   nameLast?: string,
 * }} action
 * @return {DaisyPatientLinkDialogUiState}
 */
const reducer = (state, action) => {
  const currentState = state.state;
  const transition = action.transition;

  switch (currentState) {
    case "init":
      if (transition === "search") return stateTransition(state, "searching");
      if (transition === "txt-fn")
        return searchFieldUpdate(state, "iptFirstName", action.value);
      if (transition === "txt-ln")
        return searchFieldUpdate(state, "iptLastName", action.value);
      break;
    case "searching":
      if (transition === "search_r")
        return stateTransition(state, "results", action.searchResults);
      if (transition === "fail") return stateTransition(state, "init");
      break;
    case "results":
      if (transition === "reset") {
        const r = stateTransition(state, "init", [], null);
        r.iptFirstName = action.nameFirst;
        r.iptLastName = action.nameLast;
        return r;
      }
      if (transition === "select")
        return stateTransition(state, "selected", null, action.selectedDaisyId);
      if (transition === "txt-fn")
        return searchFieldUpdate(state, "iptFirstName", action.value);
      if (transition === "txt-ln")
        return searchFieldUpdate(state, "iptLastName", action.value);
      if (transition === "search") return stateTransition(state, "searching");
      break;
    case "selected":
      if (transition === "reset") {
        const r = stateTransition(state, "init", [], null);
        r.iptFirstName = action.nameFirst;
        r.iptLastName = action.nameLast;
        return r;
      }
      if (transition === "unselect")
        return stateTransition(state, "results", null, null);
      if (transition === "select")
        return stateTransition(state, "selected", null, action.selectedDaisyId);

      if (transition === "commit") return stateTransition(state, "committing");
      break;
    case "committing":
      if (transition === "commit_r")
        return stateTransition(state, "committed", []);
      if (transition === "fail")
        return stateTransition(state, "error", null, undefined, action.value);
      break;
    case "error":
      if (transition === "commit") return stateTransition(state, "committing");
      break;
    case "committed":
    default:
      return state;
  }

  console.warn("Invalid state transition...", state, action);
  return state;
};

/**
 * Generally move the UI from one state to another
 *
 * @param {DaisyPatientLinkDialogUiState} prevUiState
 * @param {DaisyPatientLinkDialogState} newState
 * @param {Array<DaisyPatientLinkDialogSearchResult>} searchResults
 * @param {number|null|undefined} newDaisyId
 * @param {string|undefined} errStr
 * @returns {DaisyPatientLinkDialogUiState}
 */
const stateTransition = (
  prevUiState,
  newState,
  searchResults = null,
  newDaisyId = undefined,
  errStr = undefined
) => {
  return {
    state: newState,
    searchResults: !searchResults
      ? [...prevUiState.searchResults]
      : [...searchResults],
    selectedDaisyId:
      newDaisyId === undefined ? prevUiState.selectedDaisyId : newDaisyId,
    iptFirstName: prevUiState.iptFirstName,
    iptLastName: prevUiState.iptLastName,
    errStr: errStr,
  };
};

/**
 * Update the search field values
 *
 * Technically this is a "state transition", but it really is just a way to use the same reducer
 * for the search field updates.
 *
 * @param {DaisyPatientLinkDialogUiState} prevUiState
 * @param {"iptFirstName" | "iptLastName"} field
 * @param {string} val
 * @returns {DaisyPatientLinkDialogUiState}
 */
const searchFieldUpdate = (prevUiState, field, val) => {
  return {
    state: prevUiState.state,
    searchResults: [...prevUiState.searchResults],
    selectedDaisyId: prevUiState.selectedDaisyId,
    iptFirstName: field === "iptFirstName" ? val : prevUiState.iptFirstName,
    iptLastName: field === "iptLastName" ? val : prevUiState.iptLastName,
  };
};

/**
 * Used to generate the initial UI state when the component is first rendered
 *
 * @param {number | null} daisyId
 * @param {string} nameFirst
 * @param {string} nameLast
 * @returns {DaisyPatientLinkDialogUiState}
 */
const initialState = ({ daisyId, nameFirst, nameLast }) => {
  if (!daisyId) {
    return {
      state: "init",
      searchResults: [],
      selectedDaisyId: null,
      iptFirstName: nameFirst,
      iptLastName: nameLast,
    };
  }

  return {
    state: "committed",
    searchResults: [],
    selectedDaisyId: daisyId,
    iptFirstName: "",
    iptLastName: "",
  };
};

const DaisyPatientLinkDialog = ({
  open,
  onClose,
  api,
  refresh,
  daisyId,
  eocId,
  nameFirst = "",
  nameLast = "",
}) => {
  const [uiState, dispatch] = useReducer(
    reducer,
    {
      daisyId,
      nameFirst,
      nameLast,
    },
    initialState
  );

  const onSearchSubmit = async (e) => {
    e.preventDefault();
    dispatch({ transition: "search" });
    try {
      const result = await api.daisyPatientSearch(
        uiState.iptFirstName,
        uiState.iptLastName
      );
      if (result.status !== "ok") {
        throw new Error(result.message);
      }
      dispatch({ transition: "search_r", searchResults: result.data });
    } catch (err) {
      dispatch({ transition: "fail" });
    }
  };

  const onCommitSubmit = async () => {
    dispatch({ transition: "commit" });
    try {
      const result = await api.daisyPatientLink(eocId, uiState.selectedDaisyId);
      if (result.status !== "ok") throw new Error(result.message);
      dispatch({ transition: "commit_r" });
      onClose();
      refresh();
    } catch (err) {
      dispatch({ transition: "fail", value: err.message });
    }
  };

  const searchFieldsDisabled =
    uiState.state !== "init" && uiState.state !== "results";
  const resetDisabled =
    uiState.state !== "results" &&
    uiState.state !== "selected" &&
    uiState !== "error";
  const showSearchControls = uiState.state !== "committed";
  const showSearchResults =
    uiState.state === "results" ||
    uiState.state === "selected" ||
    uiState.state === "committing" ||
    uiState.state === "error";
  const showLinkButton =
    uiState.state === "selected" ||
    uiState.state === "committing" ||
    uiState.state === "error";

  return (
    <StandardDialog
      open={open}
      onClose={onClose}
      title="Link DaisyBill Patient"
    >
      <Stack direction="column" spacing={2}>
        {showSearchControls && (
          <form onSubmit={onSearchSubmit}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ pt: 1, alignItems: "center" }}
            >
              <Typography>Search DaisyBill:</Typography>
              <TextField
                size="small"
                type="search"
                name="nameFirst"
                label="First Name"
                value={uiState.iptFirstName}
                disabled={searchFieldsDisabled}
                onChange={(e) => {
                  dispatch({ transition: "txt-fn", value: e.target.value });
                }}
              />
              <TextField
                size="small"
                type="search"
                name="nameLast"
                label="Last Name"
                value={uiState.iptLastName}
                disabled={searchFieldsDisabled}
                onChange={(e) => {
                  dispatch({ transition: "txt-ln", value: e.target.value });
                }}
              />
              <Button type="submit" disabled={searchFieldsDisabled}>
                Search
              </Button>
              <Button
                type="button"
                color="error"
                disabled={resetDisabled}
                onClick={() => {
                  dispatch({ transition: "reset", nameFirst, nameLast });
                }}
              >
                Reset
              </Button>
            </Stack>
          </form>
        )}
        {showSearchResults && (
          <DaisyPatientList
            searchResults={uiState.searchResults}
            daisyId={uiState.selectedDaisyId}
            onSelect={(daisyId) => {
              if (daisyId === null) {
                dispatch({ transition: "unselect" });
              } else {
                dispatch({ transition: "select", selectedDaisyId: daisyId });
              }
            }}
          />
        )}
        {showLinkButton && (
          <Stack
            direction="row-reverse"
            spacing={1}
            sx={{ alignItems: "center" }}
          >
            <Button
              startIcon={<LinkIcon />}
              onClick={onCommitSubmit}
              disabled={uiState.state === "committing"}
            >
              Link Patient to DaisyBill Record
            </Button>
            {uiState.state === "committing" && <CircularProgress size={32} />}
            {uiState.state === "error" && (
              <Alert severity="error">{uiState.errStr}</Alert>
            )}
          </Stack>
        )}
      </Stack>
    </StandardDialog>
  );
};

export default DaisyPatientLinkDialog;
