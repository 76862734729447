import { Paper } from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";

const AttributionSurface = ({ repId, repName, agencyId, agencyName }) => {
  return (
    <Paper variant="outlined" sx={{ py: 1, px: 2 }}>
      Assigned Rep: <InternalLink to={`/reps/${repId}`}>{repName}</InternalLink>{" "}
      from{" "}
      <InternalLink to={`/agencies/${agencyId}`}>{agencyName}</InternalLink>
    </Paper>
  );
};

export default AttributionSurface;
