import ArrowsDownToPeopleIcon from "./Icons/ArrowsDownToPeopleIcon";
import SeedlingIcon from "./Icons/SeedlingIcon";
import { Stack, Tooltip } from "@mui/material";
import MoneyCheckDollarPenIcon from "./Icons/MoneyCheckDollarPenIcon";

const ICON_MAP = {
  referral: <ArrowsDownToPeopleIcon color="primary" sx={{ p: "1px" }} />,
  practice_engagement: <SeedlingIcon color="success" sx={{ p: "3px" }} />,
  payor_caution: <MoneyCheckDollarPenIcon color="warning" sx={{ p: "1px" }} />,
};

const TagIcon = ({ type, val, label }) => {
  if (!val) return null;
  return (
    <Tooltip title={label} arrow placement="top-end" enterDelay={10}>
      {ICON_MAP[type]}
    </Tooltip>
  );
};

/**
 * @param {{
 *   referral: boolean,
 *   practice_engagement: boolean,
 *   porg_id: number,
 * }} patient
 */
const TagIconList = ({ patient }) => {
  return (
    <Stack direction="row" spacing={1}>
      <TagIcon type="referral" val={patient.referral} label="Referral" />
      <TagIcon
        type="practice_engagement"
        val={patient.practice_engagement}
        label="Practice Engagement Program"
      />
      <TagIcon
        type="payor_caution"
        val={patient.porg_id === 77 || patient.porg_id === 42}
        label={
          patient.porg_id === 77
            ? "Payor is Homelink"
            : patient.porg_id === 42
            ? "Payor is One Call"
            : "Payor Caution"
        }
      />
    </Stack>
  );
};

export default TagIconList;
