import { Card, CardContent, CardHeader, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import KeyValueList from "../../PagePartials/KeyValueList";
import { phoneFmt } from "../../../formatters";

const fmtContactPref = (pref) =>
  pref === "wrs" ? "WRS" : pref === "rep" ? "Rep" : "??";

const fmtFitPref = (pref) =>
  pref === "unless-denied"
    ? "Fit Unless Denied"
    : pref === "without-approval"
    ? "Fit Without Approval"
    : "Unknown!";

const ConfigurationCard = ({
  service_channel,
  ret_and_growth,
  allow_ext,
  com_pref_ext,
  com_pref_missing,
  fit_pref,
  phone,
  email,
  onEditIconClick,
}) => {
  return (
    <Card variant="outlined">
      <CardHeader
        title="Configuration"
        titleTypographyProps={{ variant: "h6" }}
        sx={{ pb: 0 }}
        action={
          <IconButton onClick={onEditIconClick} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <KeyValueList
          kvData={{
            "Service Channel": service_channel,
            "Retention and Growth": ret_and_growth ? "yes" : "no",
            "Extensions Allowed": allow_ext ? "yes" : "no",
            "Contact on Extensions": fmtContactPref(com_pref_ext),
            "Contact on Missing Data": fmtContactPref(com_pref_missing),
            "Fitting Strategy": fmtFitPref(fit_pref),
            "Direct Phone": phoneFmt(phone),
            "Direct Email": email,
          }}
        />
      </CardContent>
    </Card>
  );
};

export default ConfigurationCard;
