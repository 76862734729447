import { useForm } from "react-hook-form";
import StandardDialog from "../../StandardComponents/StandardDialog";
import RHFPhoneField from "../../FormControlsRHF/RHFPhoneField";
import RHFTextField from "../../FormControlsRHF/RHFTextField";
import RHFComPrefPicker from "../../FormControlsRHF/RHFComPrefPicker";
import RHFCheckbox from "../../FormControlsRHF/RHFCheckbox";
import { Box, Button } from "@mui/material";
import useStdFormErrors from "../../../HOC/useStdFormErrors";
import FormLevelErrorAlert from "../../StandardComponents/FormLevelErrorAlert";
import RHFFitPrefPicker from "../../FormControlsRHF/RHFFitPrefPicker";

const PrescEditSettingsDialog = ({
  npi,
  open,
  onClose,
  api,
  refresh,
  initialVals,
}) => {
  const defaultValues = {
    allow_ext: initialVals.allow_ext,
    ret_and_growth: initialVals.ret_and_growth,
    com_pref_ext: RHFComPrefPicker.byVal(initialVals.com_pref_ext),
    com_pref_missing: RHFComPrefPicker.byVal(initialVals.com_pref_missing),
    fit_pref: RHFFitPrefPicker.byVal(initialVals.fit_pref),
    phone: initialVals.phone,
    email: initialVals.email,
  };
  const { control, handleSubmit, setError } = useForm({ defaultValues });

  const onSuccess = () => {
    refresh();
    onClose();
  };

  const { apiWrapper, formErrState } = useStdFormErrors(
    setError,
    defaultValues,
    onSuccess
  );

  const onSubmit = (data) => {
    const payload = {
      ...data,
      com_pref_ext: data.com_pref_ext.value,
      com_pref_missing: data.com_pref_missing.value,
      fit_pref: data.fit_pref.value,
    };
    apiWrapper(api.updatePrescSettings(npi, payload));
  };

  return (
    <StandardDialog open={open} onClose={onClose} title="Edit Settings">
      <FormLevelErrorAlert formErrStruct={formErrState} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <RHFCheckbox
          control={control}
          name="allow_ext"
          label="Prescriber Allows Extensions"
        />
        <RHFCheckbox
          control={control}
          name="ret_and_growth"
          label="Retention and Growth Program"
        />
        <RHFComPrefPicker
          control={control}
          name="com_pref_ext"
          label="Contact on Extensions"
        />
        <RHFComPrefPicker
          control={control}
          name="com_pref_missing"
          label="Contact on Missing Data"
        />
        <RHFFitPrefPicker
          control={control}
          name="fit_pref"
          label="Fitting Strategy"
        />
        <RHFPhoneField control={control} name="phone" label="Direct Phone" />
        <RHFTextField control={control} name="email" label="Direct Email" />
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Box>
      </form>
    </StandardDialog>
  );
};

export default PrescEditSettingsDialog;
