import {
  Alert,
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { InternalLink } from "../../StandardComponents/InternalLink";
import KeyValueList from "../../PagePartials/KeyValueList";
import { phoneFmt } from "../../../formatters";
import RepRoleChipList from "../../StandardComponents/RepRoleChipList";
import {
  dateObjFormatToAnnArborDateTime,
  dateObjFormatToWRSDate,
} from "../../../utility";
import StdConfirmSubmitDialog from "../../PagePartials/StdConfirmSubmitDialog";
import useDialogState from "../../../HOC/useDialogState";

const RepHeaderCard = ({
  repId,
  repName,
  agencyId,
  agencyName,
  email,
  phone,
  serviceChannel,
  roles,
  createdOn,
  user,
  serviceRep,
  api,
  refresh,
}) => {
  const {
    open: centralOpen,
    openFn: centralHandleOpen,
    closeFn: centralHandleClose,
  } = useDialogState();
  const hasCentralAccount = !!user;

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Paper variant="outlined" sx={{ width: "100%", p: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>General</Typography>
          <Divider />
          <KeyValueList
            kvData={{
              Agency: (
                <InternalLink to={`/agencies/${agencyId}`}>
                  {agencyName}
                </InternalLink>
              ),
              "Service Rep": !!serviceRep ? (
                <InternalLink to={`/reps/${serviceRep.id}`}>
                  {serviceRep.name}
                </InternalLink>
              ) : (
                "[NONE]"
              ),
              Email: email,
              Phone: phoneFmt(phone),
              Channel: serviceChannel,
              Created: dateObjFormatToWRSDate(createdOn),
              Roles: <RepRoleChipList roleList={roles} />,
            }}
            ignoreTypos={["Roles"]}
            noItemXPadding
            noItemYPadding
          />
        </Paper>
        <Paper variant="outlined" sx={{ width: "100%", p: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>Central</Typography>
          <Divider />
          {hasCentralAccount ? (
            <KeyValueList
              kvData={{
                "User ID": user.id,
                "Auth0 ID": (
                  <Box component="span" sx={{ fontFamily: "monospace" }}>
                    {user.auth0_id}
                  </Box>
                ),
                Permissions: user.perms,
                "Last Auth0 Sync": dateObjFormatToAnnArborDateTime(
                  user.last_sync,
                  true
                ),
                "Last Login": dateObjFormatToAnnArborDateTime(
                  user.last_login,
                  true
                ),
                "Login Count": user.login_count,
              }}
              noItemXPadding
              noItemYPadding
            />
          ) : (
            <Alert
              severity="warning"
              sx={{ mt: 2 }}
              action={
                <Button
                  size="small"
                  onClick={centralHandleOpen}
                  color="inherit"
                >
                  Fix
                </Button>
              }
            >
              Central account not setup.
            </Alert>
          )}
        </Paper>
      </Stack>
      <StdConfirmSubmitDialog
        title="Setup Central Account?"
        open={centralOpen}
        handleClose={centralHandleClose}
        onSubmit={() => api.repSetupUser(repId)}
        onComplete={() => {
          refresh();
          centralHandleClose();
        }}
      >
        Set {repName} up for a WRS Central Account? Let {repName} to go to{" "}
        <Box component="span" sx={{ fontWeight: "bold" }}>
          central.wrs.us
        </Box>{" "}
        and click the "Forgot password" link to get started!
      </StdConfirmSubmitDialog>
    </>
  );
};

export default RepHeaderCard;
