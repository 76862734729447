import { InternalLink } from "../Components/StandardComponents/InternalLink";
import { daysRelative, surgeryStr } from "../utility";
import { Avatar, Tooltip } from "@mui/material";
import { WSUserAvatar } from "./ws-shared";

export const billingSection = {
  title: "Billing Team",
  key: "sec-billing",
  buckets: [
    "ord-needs-assign",
    "ord-notice",
    "ord-pending",
    "ord-pending-po",
    "ord-need-bill",
  ],
  filterBuilderFn: (data) => {
    if (!data) return null;
    const list = data.reduce((acc, cur) => {
      if (!acc[cur.guide_id])
        acc[cur.guide_id] = { count: 0, guide: cur.guide_name };
      acc[cur.guide_id].count += 1;
      return acc;
    }, {});

    return {
      opts: Object.fromEntries(
        Object.entries(list).map(([value, dat]) => {
          return [value, `(${dat.count}) ${dat.guide}`];
        })
      ),
      matchingCol: "guide_id",
    };
  },
};

export const orderNeedsAssignment = [
  "ord-needs-assign",
  {
    title: "Assign",
    description: "",
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <>
            (<InternalLink to={`/orders/${r.id}`}>ORD</InternalLink>){" "}
            <InternalLink to={`/patients/${r.eoc_id}`}>
              {[r.name_first, r.name_last].join(" ")}
            </InternalLink>
          </>
        ),
      },
      {
        label: "Surgery",
        cell: (r) => surgeryStr(r.surgical, r.date_of_surgery),
      },
      {
        label: "Gen",
        cell: (r) => r.gen_str,
      },
      {
        label: "Guide",
        cell: (r) => (
          <Tooltip title={r.guide_name} placement="left" arrow>
            <Avatar
              alt={r.guide_name}
              src={r.guide_pic}
              sx={{ height: 24, width: 24 }}
            >
              {r.guide_name}
            </Avatar>
          </Tooltip>
        ),
      },
    ],
  },
];

export const orderHasNotice = [
  "ord-notice",
  {
    title: "Notices",
    description: "",
    columns: [
      {
        label: "Patient",
        cell: (r) => (
          <>
            (<InternalLink to={`/orders/${r.id}`}>ORD</InternalLink>){" "}
            <InternalLink to={`/patients/${r.eoc_id}`}>
              {[r.name_first, r.name_last].filter(Boolean).join(" ")}
            </InternalLink>
          </>
        ),
      },
      {
        label: "Status",
        cell: (r) => r.status,
      },
      {
        label: "Notice",
        cell: (r) => r.notices.map((n) => <div key={n}>{n}</div>),
      },
      {
        label: "Guide",
        cell: (r) => (
          <WSUserAvatar guide_name={r.guide_name} guide_pic={r.guide_pic} />
        ),
      },
    ],
  },
];

export const orderPending = [
  "ord-pending",
  {
    title: "Pending",
    description: "",
    columns: [
      {
        label: "Patient Name",
        cell: (r) => (
          <InternalLink to={`/patients/${r.id}`}>
            {[r.name_first, r.name_last].join(" ")}
          </InternalLink>
        ),
      },
      {
        label: "Order",
        cell: (r) => (
          <InternalLink to={`/orders/${r.order_id}`}>{`ORD-${r.order_id
            .toString()
            .padStart(4, "0")}`}</InternalLink>
        ),
      },
      {
        label: "Surgery",
        cell: (r) => surgeryStr(r.surgical, r.date_of_surgery),
      },
      {
        label: "Generation",
        cell: (r) => r.gen_str,
      },
      {
        label: "Guide",
        cell: (r) => (
          <Tooltip title={r.guide_name} placement="left" arrow>
            <Avatar
              alt={r.guide_name}
              src={r.guide_pic}
              sx={{ height: 24, width: 24 }}
            >
              {r.guide_name}
            </Avatar>
          </Tooltip>
        ),
      },
    ],
  },
];

export const orderPendingPo = [
  "ord-pending-po",
  {
    title: "Pending PO",
    description: "",
    columns: [
      {
        label: "Patient Name",
        cell: (r) => (
          <InternalLink to={`/patients/${r.id}`}>
            {[r.name_first, r.name_last].join(" ")}
          </InternalLink>
        ),
      },
      {
        label: "Order",
        cell: (r) => (
          <InternalLink to={`/orders/${r.order_id}`}>{`ORD-${r.order_id
            .toString()
            .padStart(4, "0")}`}</InternalLink>
        ),
      },
      {
        label: "Surgery",
        cell: (r) => surgeryStr(r.surgical, r.date_of_surgery),
      },
      {
        label: "Generation",
        cell: (r) => r.gen_str,
      },
      {
        label: "Guide",
        cell: (r) => (
          <Tooltip title={r.guide_name} placement="left" arrow>
            <Avatar
              alt={r.guide_name}
              src={r.guide_pic}
              sx={{ height: 24, width: 24 }}
            >
              {r.guide_name}
            </Avatar>
          </Tooltip>
        ),
      },
    ],
  },
];

export const orderNeedsBilling = [
  "ord-need-bill",
  {
    title: "Needs Billing",
    description: "",
    columns: [
      {
        label: "Order",
        cell: (r) => (
          <InternalLink to={`/orders/${r.id}`}>{`ORD-${r.id
            .toString()
            .padStart(4, "0")}`}</InternalLink>
        ),
      },
      {
        label: "Patient Name",
        cell: (r) => (
          <InternalLink to={`/patients/${r.eoc_id}`}>
            {[r.name_first, r.name_last].join(" ")}
          </InternalLink>
        ),
      },
      {
        label: "Days Unbilled",
        cell: (r) => daysRelative(r.days_ago),
      },
      {
        label: "Guide",
        cell: (r) => (
          <Tooltip title={r.guide_name} placement="left" arrow>
            <Avatar
              alt={r.guide_name}
              src={r.guide_pic}
              sx={{ height: 24, width: 24 }}
            >
              {r.guide_name}
            </Avatar>
          </Tooltip>
        ),
      },
    ],
  },
];
